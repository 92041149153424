import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import Banner from "../../../components/common/Banner";
import BottomContainer from "../../../components/common/SectionBottom/BottomContainer";
import BottomHeader from "../../../components/common/SectionBottom/BottomHeader";
import BottomBody from "../../../components/common/SectionBottom/BottomBody";
import BottomFooter from "../../../components/common/SectionBottom/BottomFooter";

// Images
import donateBanner2 from "Assets/images/donate/donate-banner-2.png";
import donateBanner2Mobile from "Assets/images/donate/donate-banner-2.png";

import { useGlobalState } from "../../../context/GlobalStateProvider";

const donationBoxes = [
  {
    type: "normal",
    index: 0,
    title: "How does my donation help?",
    content:
      "MedicAlert ™ Foundation is a not-for-profit, member-based organisation that does not receive any government funding to provide its services. Your decision to donate will assist the Foundation to help others less fortunate in need of our vital services. Donations also allow us to maintain our awareness programs throughout Malaysia.",
    toggled: true,
  },
  {
    type: "normal",
    index: 1,
    title: "How can I make a donation?",
    content:
      "No donation amount is too large or too small. We appreciate your continued support in our mission to provide all Malaysians with potentially life-threatening health conditions or special medical needs with our protection. Donations can be made via a deposit into our bank account. Please see our bank details:",
    toggled: false,
  },
  // {
  //   type: "special",
  //   index: 2,
  //   title: "Bank Details",
  //   content: {
  //     bank_details: "Bank Details: UOB Bank",
  //     bank_account: "Bank Account Number: 1733025692",
  //     account_name: "Bank Account Holder's Name: Yayasan Medicalert Malaysia",
  //     merchant_no: "Merchant No.01127",
  //     mobile_no: "Mobile No. 0775-693202",
  //     registration_no: "Welfare Organisation Registration Number W.O.20/83.",
  //   },
  //   toggled: false,
  // },
];

function Donate() {
  const { isMobile } = useGlobalState();

  return (
    <div className="donate-page">
      {/* Header */}
      <section className="top-title">
        <div>Donate To</div>
        <div className="primary-color">A Worthy Cause</div>
      </section>

      {/* Banner */}
      {/* <Banner webBanner={donateBanner} mobileBanner={donateBannerMobile} /> */}

      {/* Section Btm */}
      <BottomContainer>
        <BottomHeader>
          <div className="header-title donate">
            <span className="primary-color">
              No donation amount is too large or too small.
            </span>{" "}
            Your decision to donate will assist the Foundation to help others
            less fortunate in need of our vital services.
          </div>
        </BottomHeader>
        <BottomBody>
          <div className="small-banner">
            <Banner
              webBanner={donateBanner2}
              mobileBanner={donateBanner2Mobile}
            />
          </div>

          {/* donation box */}
          <div className="container">
            <div className="donation-boxes">
              {donationBoxes.map((item) => {
                return <DonationBox item={item} key={item.index} />;
              })}
            </div>
          </div>
        </BottomBody>
        <BottomFooter>
          <div className="title">
            <div className="title-head">Let’s talk! </div>
            <div className="title-desc">
              Learn more about the membership & its benefits.
            </div>
          </div>

          <Link
            className="primary-btn"
            to={isMobile ? "/sign-up" : "/membership"}
          >
            Donate Now
          </Link>
        </BottomFooter>
      </BottomContainer>
    </div>
  );
}

function DonationBox({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useGlobalState();

  useEffect(() => {
    if (isMobile) setIsOpen(item.index === 0 ? true : false);
    else setIsOpen(false);
  }, [isMobile]);

  const handleOpen = () => {
    if (!isMobile) return;
    setIsOpen((isOpen) => !isOpen);
  };

  const contentStyle = {
    maxHeight: isOpen ? "500px" : "7.2rem",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  };

  return (
    <div
      className={`donation-box ${
        item.type === "special" ? "special-box" : ""
      } ${isOpen ? "opened" : ""}`}
      style={isMobile ? contentStyle : {}}
      onClick={handleOpen}
    >
      <div className="box-title">
        {item.title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.599"
          height="9"
          viewBox="0 0 16.599 9"
          className="m-dev"
        >
          <path
            id="Polygon_31"
            data-name="Polygon 31"
            d="M8.3,0l8.3,9H0Z"
            transform="translate(16.599 9) rotate(180)"
            fill="#061528"
          />
        </svg>
      </div>
      <div className="box-content">
        {item.content.length && item.content}
        {!item.content.length && (
          <ul>
            <li>{item.content.bank_details}</li>
            <li>{item.content.bank_account}</li>
            <li>{item.content.account_name}</li>
            {/* <div>{item.content.merchant_no}</div>
            <div>{item.content.mobile_no}</div>
            <div>{item.content.registration_no}</div> */}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Donate;
