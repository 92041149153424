import React from "react";
import { Link } from "react-router-dom";

function DonateBar() {
  return (
    <div className="donate-bar">
      <div className="donate-bar-container">
        <div className="donate-bar-desc">
          <div>Help us protect and save more lives.</div>
        </div>
        <Link to="/donate" className="donate-button">
          Donate
        </Link>
      </div>
    </div>
  );
}

export default DonateBar;
