import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

function PricingPackageMembership({
  handleSelectPackage,
  pricingPackageList,
  pricingPackageRef,
  listingOnly
}) {
  return (
    <div className="pricing-package-container m-t-2" ref={pricingPackageRef}>
      <div className="pricing-boxes">
        {pricingPackageList.map((item) => (
          <div
            className={`pricing-box ${pricingPackageList.length == 1 ? 'mw-75' : ''}`}
            onClick={() => listingOnly ? null : handleSelectPackage(item.packageType, item.package, item)}
            key={item.price}
          >
            <div className="m-b-2">
              <div className="pricing-box-title">For every {item.price}</div>
              {item.tips && (
                <div className="pricing-box-tips font-italic">{item.tips}</div>
              )}
            </div>

            {item?.desc && (
              <ul className="pricing-box-desc text-left fs-14">
                {item?.desc?.split("\n")?.map((desc, i) => (
                  <li key={`list-${item.price}-${i}`}>{desc}</li>
                ))}
              </ul>
            )}

            <div className="pricing-box-amt">{item.foreignerPrice}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PricingPackageMembership;
