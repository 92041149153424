import React, { useState, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { uploadResources, removeResource } from "../../../../../ducks/services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

export default (props) => {
  const { user, reloadApi } = props;
  const [data, setData] = useState([]);
  const { register, setValue } = useForm();

  useEffect(() => {
    if (user) {
      setData(user.resources);
    }
  }, [user]);

  const handleChange = async (event) => {
    props.setLoading(true);

    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      try {
        await uploadResources(fileUploaded);
        props.setLoading(false);
        setValue("uploadfile", null);
        reloadApi();
      } catch (error) {
        props.setLoading(false);
        toast.error(`Error during file upload: ${error}`);
      }
    }
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    await removeResource({ file_id: id })
      .then((res) => {
        props.setLoading(false);
        if (res.data.code === 200) {
          toast.success("File removed successfully");
          reloadApi();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(`Error during file removal: ${e.message}`);
      });
  };

  return (
    <div className="next-of-kin">
      <div className="row">
        <div className="col-12">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              Upload your most recent allergy/medical report
            </Form.Label>
            <Form.Control
              size="lg"
              type="file"
              accept="image/*,.pdf"
              {...register("uploadfile")}
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        {data?.length > 0 && (
          <div className="col-12 mt-5">
            <Table hover>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Filename</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((x, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <a href={x.url} target="_blank">
                          {x.filename}
                        </a>
                      </td>
                      <td>
                        <a href="#" onClick={() => onRemove(x.id)}>
                          Remove
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};
