import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import accPageLogo from "Assets/images/acc-page-logo.svg";
import accIcon from "Assets/images/account-icon.svg";
import { Form, Button } from "react-bootstrap";
import { getUser } from "../ducks/actions";
import { renewPlan, uploadProfilePicture } from "../ducks/services";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useGlobalState } from "../../../context/GlobalStateProvider";
import { Modal } from "react-bootstrap";

import PricingPackage from "../../../components/PricingPackage";

// Constant Data
import { csPrefillTxt } from "../../../configs/constantData";

export default (props) => {
  const dispatch = useDispatch();
  const { isMobile } = useGlobalState();
  const userId = localStorage.getItem("userid");
  const [activeTab, setActiveTab] = useState("both");
  const [copied, setCopied] = useState(false);
  const user = useSelector((state) => state.dashboard.user);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [openRenewModal, setOpenRenewModal] = useState(false);
  const [openRenew3yModal, setOpenRenew3yModal] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const pricingPackageRef = useRef(null);
  // const { register, setValue } = useForm();

  const pricingPackageList = [
    {
      title: user?.is_new_user ? "New Membership" : "Yearly Renewal",
      price: user?.is_new_user ? "RM250" : "RM200",
      package: user?.is_new_user ? "NEW_1Y" : "RENEWAL_1Y",
      tips: user?.is_new_user ? "" : "(For Existing Member)",
    },
    {
      title: "3 Years Renewal",
      price: "RM450",
      package: user?.is_new_user ? "NEW_3Y" : "RENEWAL_3Y",
      tips: user?.is_new_user ? "" : "(For Existing Member)",
    },
    {
      title: "Replacement",
      price: "RM100",
      package: "REPLACEMENT",
      tips: "(For Existing Member)",
    },
  ];

  const handleSelectPackage = (name) => {
    setSelectedPackage(name);
    if (name === "NEW_1Y" || name === "RENEWAL_1Y") setOpenRenewModal(true);
    if (name === "NEW_3Y" || name === "RENEWAL_3Y") setOpenRenew3yModal(true);
    if (name === "REPLACEMENT") setOpenReplaceModal(true);
  };

  const handleCloseRenewModal = () => {
    setOpenRenewModal(false);
    setSelectedPackage("");
  };

  const handleCloseRenew3yModal = () => {
    setOpenRenew3yModal(false);
    setSelectedPackage("");
  };

  const handleCloseReplaceModal = () => {
    setOpenReplaceModal(false);
    setSelectedPackage("");
  };

  const handleClickToScroll = () => {
    pricingPackageRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const noData = "No data";

  useEffect(() => {
    if (isMobile) setActiveTab("account");
    else setActiveTab("both");
  }, [isMobile]);

  useEffect(() => {
    dispatch(getUser(userId, props.setLoading));
  }, []);

  useEffect(() => {
    if (user) {
      // setValue("name", user.name);
      // setValue("dob", user.dob);
      // setValue("emergency_contact_name", user.emergency_contact_name);
      // setValue("phone", user?.phone ?? user?.emergency_contact_phone);
      // if (user.blood_type) setValue("blood_type", user.blood_type);
      // setValue("dnr", user?.dnr === "Other" ? user?.other_dnr : user?.dnr);
      // if (user.remarks) setValue("remarks", user.remarks);
      // if (user.medical_remarks)
      //   setValue("medical_remarks", user.medical_remarks);
      // if (user.medical_info) setValue("medical_info", user.medical_info);
      // if (user.bio_us) setValue("bio_us", user.bio_us);
      localStorage.setItem("is_new_user", user?.is_new_user ? "1" : "0");
    }
  }, [user]);

  const handleActiveTab = (value) => {
    setActiveTab(value);
  };

  const renewMembership = async () => {
    props.setLoading(true);

    const payload = {
      product_code: selectedPackage,
    };
    await renewPlan(payload)
      .then((res) => {
        props.setLoading(false);
        if (res.data.code === 200) {
          window.location.href = res.data.data.url;
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  const onCopy = () => {
    if (!copied) {
      setCopied(true);
      toast.success("Copied link successfully!");
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    // event.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    props.setLoading(true);

    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      try {
        await uploadProfilePicture(fileUploaded);
        props.setLoading(false);
        dispatch(getUser(userId, props.setLoading));
      } catch (error) {
        props.setLoading(false);
        toast.error(`Error during file upload: ${error}`);
      }
    }
  };

  return (
    <>
      <div className="account-container">
        <div className="m-dev">
          <div className="account-info-tabs font-bold">
            <div
              className={`account-info-tab cursor-pointer ${
                activeTab === "account" ? "active" : ""
              }`}
              onClick={() => handleActiveTab("account")}
            >
              My Account
            </div>
            <div
              className={`account-info-tab cursor-pointer ${
                activeTab === "medical" ? "active" : ""
              }`}
              onClick={() => handleActiveTab("medical")}
            >
              Medical Info
            </div>
          </div>
        </div>
        {(activeTab === "account" || activeTab === "both") && (
          <div className="account-info-top-row">
            <div className="account-info-box">
              <div
                className={`account-info-img ${user?.photo_url ? "" : "no-bg"}`}
              >
                {user?.photo_url && (
                  <img
                    src={user?.photo_url}
                    alt="medical alert logo"
                    width="100%"
                    className="profile-img"
                  />
                )}

                {!user?.photo_url && <div>Upload Profile Photo</div>}

                <div
                  className="small-icon"
                  onClick={(e) => handleClick(e)}
                  style={{ cursor: "pointer" }}
                >
                  <img src={accIcon} alt="acc info icon" width="100%" />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
              </div>
              <div className="account-info-basic">
                <div className="account-info-box-header font-bold d-dev">
                  <div className="mb-3">
                    Welcome Back,{" "}
                    <span className="primary-color">{user?.name}</span>
                  </div>
                  <div>Member ID: {user?.username}</div>
                </div>
                {/* <div>Protection Plan: {user?.protection_plan ?? "Basic New"}</div> */}
                <div className="d-dev">
                  <Button
                    className="primary-btn w-100"
                    onClick={handleClickToScroll}
                  >
                    {user?.is_new_user ? (
                      "Make Payment To Complete Sign Up"
                    ) : (
                      <>
                        Renew Date:
                        {user?.is_expired
                          ? "Expired. Click on Yearly Renewal to renew now"
                          : user?.expiry_date}
                      </>
                    )}
                  </Button>
                </div>
                <div className="renew-container text-center m-dev">
                  <div className="primary-color font-bold mb-3">
                    Renew Date:
                  </div>
                  <div className="primary-btn" onClick={handleClickToScroll}>
                    {user?.is_new_user ? (
                      "Make Payment To Complete Sign Up"
                    ) : (
                      <>
                        {user?.is_expired
                          ? "Expired. Click on Yearly Renewal to renew now"
                          : user?.expiry_date}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="account-info-box border-primary">
              <div className="smart-id-top">
                <div className="account-info-box-header font-bold mb-3">
                  <span className="primary-color">Smart ID,</span> share your
                  information.
                </div>
                <div>
                  Link:{" "}
                  <a
                    href={
                      user?.profile_url ??
                      "https://www.medicalert.my/account/profile"
                    }
                    target="_blank"
                  >
                    {user?.profile_url ??
                      "https://www.medicalert.my/account/profile"}
                  </a>
                </div>
              </div>
              <div className="account-info-box-buttons">
                <a
                  href="https://wa.me/60184710020"
                  target="_blank"
                  className="primary-btn"
                >
                  Contact Us To Update
                </a>
                <CopyToClipboard
                  text={
                    user?.profile_url ||
                    "https://www.medicalert.my/account/profile"
                  }
                  onCopy={() => onCopy()}
                >
                  <span className="secondary-btn">
                    {copied ? "Copied" : "Copy Link"}
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        )}
        {(activeTab === "medical" || activeTab === "both") && (
          <div className="account-info-btm-row">
            <div className="account-info-box card">
              <div className="card-body">
                <div className="account-info-box-header font-bold mb-3 d-dev">
                  MedicAlert ID:{" "}
                  <span className="primary-color">{user?.id ?? noData}</span>
                </div>
                <Form className="row">
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Name</div>
                      <div className="medical-info-content">
                        {user?.name ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">NRIC No</div>
                      <div className="medical-info-content">
                        {user?.nric ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Date of Birth</div>
                      <div className="medical-info-content">
                        {user?.dob ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Emergency Contact Name</div>
                      <div className="medical-info-content">
                        {user?.emergency_contact_name ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Contact Mobile</div>
                      <div className="medical-info-content">
                        {user?.phone ?? noData}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className="account-info-box card">
              <div className="card-body">
                <div className="account-info-box-header font-bold mb-3 d-dev">
                  Medical Info
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Blood Type</div>
                      <div className="medical-info-content">
                        {user?.blood_type ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Do not resuscitate (DNR)</div>
                      <div className="medical-info-content">
                        {user?.dnr ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Allergy Info</div>
                      <div className="medical-info-content">
                        {user?.remarks ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Medication Info</div>
                      <div className="medical-info-content">
                        {user?.medical_remarks ?? noData}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="medical-content-group">
                      <div className="mb-3">Medication Condition</div>
                      <div className="medical-info-content">
                        {user?.bio_us ?? noData}
                      </div>
                    </div>
                  </div>
                </div>

                <a
                  href="https://wa.me/60184710020"
                  target="_blank"
                  className="primary-btn text-center mt-3 m-dev"
                >
                  Contact Us To Update
                </a>
              </div>
            </div>
          </div>
        )}

        <PricingPackage
          handleSelectPackage={handleSelectPackage}
          pricingPackageList={pricingPackageList}
          pricingPackageRef={pricingPackageRef}
        />
      </div>

      <Modal
        show={openRenewModal}
        onHide={handleCloseRenewModal}
        centered
        size="lg"
        className="renewModal"
      >
        <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <div>
            If you are an existing member and a first-time login, click{" "}
            <a
              href={csPrefillTxt}
              target="_blank"
              className="text-primary font-bold"
            >
              here
            </a>{" "}
            to speak to our Customer Service and we shall assist you in
            retrieving your first-time login ID and Password.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="secondary-btn" onClick={handleCloseRenewModal}>
            Close
          </button>
          <button className="primary-btn" onClick={renewMembership}>
            Renew Now
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openRenew3yModal}
        onHide={handleCloseRenew3yModal}
        centered
        size="lg"
        className="renewModal"
      >
        <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <div>
            For 3 years renewal of RM450 in one go. Members can enjoy a
            membership of 3 years continuously. Click{" "}
            <a
              href="https://api.whatsapp.com/send?phone=60184710020"
              target="_blank"
              className="text-primary font-bold"
            >
              here
            </a>{" "}
            to contact our Customer Service for to inquire the 3-year renewal.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="secondary-btn" onClick={handleCloseRenew3yModal}>
            Close
          </button>
          <button className="primary-btn" onClick={renewMembership}>
            Renew Now
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openReplaceModal}
        onHide={handleCloseReplaceModal}
        centered
        size="lg"
        className="renewModal"
      >
        <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <div>
            For replacement of medical bracelet / pendant. Click{" "}
            <a
              href="https://api.whatsapp.com/send?phone=60184710020"
              target="_blank"
              className="text-primary font-bold"
            >
              here
            </a>{" "}
            to contact our Customer Service for to inquire on replacement
            payments.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="secondary-btn" onClick={handleCloseReplaceModal}>
            Close
          </button>
          <button className="primary-btn" onClick={renewMembership}>
            Renew Now
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
