import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { updateEmergency } from "../../../ducks/services";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";
import { Modal } from "react-bootstrap";

export default (props) => {
  const { user, isMobile, meta } = props;
  const [emergencyContactsList, setEmergencyContactsList] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [selectedRemove, setSelectedRemove] = useState(null);
  const [lifeInsuranceContactList, setLifeInsuranceContactList] = useState([]);
  const [generalInsuranceContactList, setGeneralInsuranceContactList] =
    useState([]);
  const [removeInsuranceType, setRemoveInsuranceType] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
    setValue,
    getValues,
  } = useForm();

  const formFields = [
    {
      name: "dnr",
      topLabel: `"Do not resuscitate (DNR)"`,
      topLabelBold: true,
      label: (
        <span className="font-italic fs-14">
          {
            "(“DNR” is a medical order that indicates that you do not want to receive CPR (cardiopulmonary resuscitation) or advanced cardiac life support if your heart stops or if you stop breathing.)"
          }
        </span>
      ),
      placeholder: "Please select",
      type: "select",
      options: ["Yes", "No"],
      req: true,
      class: "col-12 mb-3",
      reqmsg: "Required",
      size: "lg",
      children: {
        type: "input",
        trigger: "Other",
        placeholder: "Please state",
        name: "other_dnr",
        req: true,
        reqmsg: "Required",
      },
    },
    {
      name: "emergency_hospital",
      label:
        "In case of emergency, and if there's a choice, do you want to be sent to a public or prviate hospital for treatment?",
      placeholder: "Please select",
      type: "select",
      options: [
        "Public hospital",
        "Private hospital",
        "Nearest hospital",
        "Other",
      ],
      req: true,
      class: "col-12 mb-3",
      reqmsg: "Required",
      size: "lg",
      children: {
        type: "input",
        trigger: "Other",
        placeholder: "Please state",
        name: "other_emergency_hospital",
        req: true,
        reqmsg: "Required",
      },
    },
    {
      name: "donor_id_number",
      topLabel:
        "I am a registered organ donor with Pusat Sumber Transplan Nasional",
      label: "ID Number",
      type: "input",
      class: "col-12 mb-3",
      size: "lg",
    },
  ];

  const emergencyContactFormFields = [
    {
      name: "Will_id",
      type: "input",
      hidden: true,
    },
    {
      name: "Will",
      type: "input",
      hidden: true,
    },
    {
      name: "Will_company",
      label: "Will",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Will_name",
      label: "Agent's Name",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Will_mobile",
      label: "Agent's Mobile Number",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Trustee_id",
      type: "input",
      hidden: true,
    },
    {
      name: "Trustee",
      type: "input",
      hidden: true,
    },
    {
      name: "Trustee_company",
      label: "Trustee",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Trustee_name",
      label: "Agent's Name",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Trustee_mobile",
      label: "Agent's Mobile Number",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Bereavement_id",
      type: "input",
      hidden: true,
    },
    {
      name: "Bereavement",
      type: "input",
      hidden: true,
    },
    {
      name: "Bereavement_company",
      label: "Bereavement",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Bereavement_name",
      label: "Agent's Name",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Bereavement_mobile",
      label: "Agent's Mobile Number",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Unit Trust_id",
      type: "input",
      hidden: true,
    },
    {
      name: "Unit Trust",
      type: "input",
      hidden: true,
    },
    {
      name: "Unit Trust_company",
      label: "Unit Trust",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Unit Trust_name",
      label: "Agent's Name",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
    {
      name: "Unit Trust_mobile",
      label: "Agent's Mobile Number",
      type: "input",
      // req: true,
      class: "col-md-4 col-12",
      reqmsg: "Required",
      size: "lg",
    },
  ];

  const addEmergencyContact = (val) => {
    const valArray =
      val === "Life" ? lifeInsuranceContactList : generalInsuranceContactList;

    const newContact = {
      form_fields: [
        {
          name: `id-${val}${
            Number(
              valArray[valArray.length - 1]?.form_fields[0].name?.split(
                `-${val}`
              )[1]
            ) + 1
          }`,
          label: "id",
          type: "input",
          req: false,
          hidden: true,
        },
        {
          name: `company-${val}${
            Number(
              valArray[valArray.length - 1]?.form_fields[0].name?.split(
                `-${val}`
              )[1]
            ) + 1
          }`,
          label: "Insurance Company Name",
          type: "input",
          req: true,
          class: "col-md-4 col-12",
          reqmsg: "Company Name is Required",
          size: "lg",
        },
        {
          name: `name-${val}${
            Number(
              valArray[valArray.length - 1]?.form_fields[0].name?.split(
                `-${val}`
              )[1]
            ) + 1
          }`,
          label: "Agent's Name",
          type: "input",
          req: true,
          class: "col-md-4 col-12",
          reqmsg: "Agent's name is Required",
          size: "lg",
        },

        {
          name: `mobile-${val}${
            Number(
              valArray[valArray.length - 1]?.form_fields[0].name?.split(
                `-${val}`
              )[1]
            ) + 1
          }`,
          label: "Agent's Mobile Number",
          type: "input",
          req: true,
          class: "col-md-4 col-12",
          reqmsg: "Agent's Mobile Number is Required",
          size: "lg",
        },
      ],
    };

    setFormValue(getValues());

    if (val === "Life") {
      setLifeInsuranceContactList((contactList) => [
        ...contactList,
        newContact,
      ]);
    } else {
      setGeneralInsuranceContactList((contactList) => [
        ...contactList,
        newContact,
      ]);
    }
  };

  const verifyRemove = (val, type) => {
    setSelectedRemove(val);
    setRemoveInsuranceType(type);
    setIsOpenRemoveModal(true);
  };

  const cancelRemove = () => {
    setSelectedRemove(null);
    setIsOpenRemoveModal(false);
  };

  const removeEmergencyContact = () => {
    props.setLoading(true);
    setValue(`id-${removeInsuranceType}${selectedRemove}`, "");
    setValue(`company-${removeInsuranceType}${selectedRemove}`, "");
    setValue(`name-${removeInsuranceType}${selectedRemove}`, "");
    setValue(`mobile-${removeInsuranceType}${selectedRemove}`, "");
    if (removeInsuranceType === "Life") {
      setLifeInsuranceContactList((contact) =>
        contact.filter((_, index) => index !== selectedRemove)
      );
    } else {
      setGeneralInsuranceContactList((contact) =>
        contact.filter((_, index) => index !== selectedRemove)
      );
    }
    setSelectedRemove(null);
    setRemoveInsuranceType(null);
    setIsOpenRemoveModal(false);
    setFormValue(getValues());
    props.setLoading(false);
    toast.success("Emergency Contact Removed");
  };

  useEffect(() => {
    if (user) {
      setValue("has_insurance", user.has_insurance);
      setValue("other_insurance", user.other_insurance);
      setValue("emergency_hospital", user.emergency_hospital);
      setValue("other_emergency_hospital", user.other_emergency_hospital);
      setValue("dnr", user.dnr);
      setValue("other_dnr", user.other_dnr);
      setValue("donor_id_number", user.donor_id_number);

      if (user?.emergency_agent_contacts) {
        user?.emergency_agent_contacts.forEach((item) => {
          if (
            item.insurance_type !== "Life" ||
            item.insurance_type !== "General"
          ) {
            setValue(`${item.insurance_type}_name`, item.name);
            setValue(`${item.insurance_type}_company`, item.company);
            setValue(`${item.insurance_type}_mobile`, item.mobile);
            setValue(`${item.insurance_type}_id`, item.id);
            setValue(`${item.insurance_type}`, item.insurance_type);
          }
        });

        const lifeList = user?.emergency_agent_contacts?.filter(
          (item) => item.insurance_type === "Life"
        );

        const generalList = user?.emergency_agent_contacts?.filter(
          (item) => item.insurance_type === "General"
        );

        const lifeData = lifeList.length
          ? lifeList.map((el, i) => ({
              ...el,
              form_fields: [
                {
                  name: `id-Life${i}`,
                  label: "id",
                  type: "input",
                  req: false,
                  hidden: true,
                },
                {
                  name: `company-Life${i}`,
                  label: "Insurance Company Name",
                  type: "input",
                  req: true,
                  class: "col-md-4 col-12",
                  reqmsg: "Company Name is Required",
                  size: "lg",
                },
                {
                  name: `name-Life${i}`,
                  label: "Agent's Name",
                  type: "input",
                  req: true,
                  class: "col-md-4 col-12",
                  reqmsg: "Agent's name is Required",
                  size: "lg",
                },

                {
                  name: `mobile-Life${i}`,
                  label: "Agent's Mobile Number",
                  type: "input",
                  req: true,
                  class: "col-md-4 col-12",
                  reqmsg: "Agent's Mobile Number is Required",
                  size: "lg",
                },
              ],
            }))
          : [
              {
                form_fields: [
                  {
                    name: `id-Life0`,
                    label: "id",
                    type: "input",
                    req: false,
                    hidden: true,
                  },
                  {
                    name: `company-Life0`,
                    label: "Insurance Company Name",
                    type: "input",
                    req: true,
                    class: "col-md-4 col-12",
                    reqmsg: "Company Name is Required",
                    size: "lg",
                  },
                  {
                    name: `name-Life0`,
                    label: "Agent's Name",
                    type: "input",
                    req: true,
                    class: "col-md-4 col-12",
                    reqmsg: "Agent's name is Required",
                    size: "lg",
                  },

                  {
                    name: `mobile-Life0`,
                    label: "Agent's Mobile Number",
                    type: "input",
                    req: true,
                    class: "col-md-4 col-12",
                    reqmsg: "Agent's Mobile Number is Required",
                    size: "lg",
                  },
                ],
              },
            ];

        const generalData = generalList.length
          ? generalList.map((el, i) => ({
              ...el,
              form_fields: [
                {
                  name: `id-General${i}`,
                  label: "id",
                  type: "input",
                  req: false,
                  hidden: true,
                },
                {
                  name: `company-General${i}`,
                  label: "Insurance Company Name",
                  type: "input",
                  req: true,
                  class: "col-md-4 col-12",
                  reqmsg: "Company Name is Required",
                  size: "lg",
                },
                {
                  name: `name-General${i}`,
                  label: "Agent's Name",
                  type: "input",
                  req: true,
                  class: "col-md-4 col-12",
                  reqmsg: "Agent's Name is Required",
                  size: "lg",
                },

                {
                  name: `mobile-General${i}`,
                  label: "Agent's Mobile Number",
                  type: "input",
                  req: true,
                  class: "col-md-4 col-12",
                  reqmsg: "Agent's Mobile Number is Required",
                  size: "lg",
                },
              ],
            }))
          : [
              {
                form_fields: [
                  {
                    name: `id-General0`,
                    label: "id",
                    type: "input",
                    req: false,
                    hidden: true,
                  },
                  {
                    name: `company-General0`,
                    label: "Insurance Company Name",
                    type: "input",
                    req: true,
                    class: "col-md-4 col-12",
                    reqmsg: "Company Name is Required",
                    size: "lg",
                  },
                  {
                    name: `name-General0`,
                    label: "Agent's Name",
                    type: "input",
                    req: true,
                    class: "col-md-4 col-12",
                    reqmsg: "Agent's Name is Required",
                    size: "lg",
                  },

                  {
                    name: `mobile-General0`,
                    label: "Agent's Mobile Number",
                    type: "input",
                    req: true,
                    class: "col-md-4 col-12",
                    reqmsg: "Agent's Mobile Number is Required",
                    size: "lg",
                  },
                ],
              },
            ];

        const lifeFormData = lifeList.reduce((acc, item, index) => {
          Object.keys(item).forEach((key) => {
            acc[`${key}-Life${index}`] = item[key];
          });
          return acc;
        }, {});

        const generalFormData = generalList.reduce((acc, item, index) => {
          Object.keys(item).forEach((key) => {
            acc[`${key}-General${index}`] = item[key];
          });
          return acc;
        }, {});

        const allData = { ...lifeFormData, ...generalFormData };

        setLifeInsuranceContactList(lifeData);
        setGeneralInsuranceContactList(generalData);
        setFormValue(allData);
      }
    }
  }, [user]);

  useEffect(() => {
    lifeInsuranceContactList.forEach((el) => {
      el?.form_fields?.forEach((form_field) => {
        setValue(`${form_field.name}`, formValue?.[form_field.name]);
      });
    });
  }, [lifeInsuranceContactList]);

  useEffect(() => {
    generalInsuranceContactList.forEach((el) => {
      el?.form_fields?.forEach((form_field) => {
        setValue(`${form_field.name}`, formValue?.[form_field.name]);
      });
    });
  }, [generalInsuranceContactList]);

  const onFinishEmergency = async (val) => {
    props.setLoading(true);

    const lifeContactPayload = lifeInsuranceContactList?.map((item) => ({
      id: val?.[item.form_fields[0].name]
        ? val?.[item.form_fields[0].name]
        : "",
      insurance_type: "Life",
      company: val?.[item.form_fields[1].name],
      name: val?.[item.form_fields[2].name],
      mobile: val?.[item.form_fields[3].name],
    }));

    const generalContactPayload = generalInsuranceContactList?.map((item) => ({
      id: val?.[item.form_fields[0].name]
        ? val?.[item.form_fields[0].name]
        : "",
      insurance_type: "General",
      company: val?.[item.form_fields[1].name],
      name: val?.[item.form_fields[2].name],
      mobile: val?.[item.form_fields[3].name],
    }));

    const oneContactPayload = [
      {
        id: val.Will_id,
        insurance_type: "Will",
        company: val.Will_company,
        name: val.Will_name,
        mobile: val.Will_mobile,
      },
      {
        id: val.Trustee_id,
        insurance_type: "Trustee",
        company: val.Trustee_company,
        name: val.Trustee_name,
        mobile: val.Trustee_mobile,
      },
      {
        id: val.Bereavement_id,
        insurance_type: "Bereavement",
        company: val.Bereavement_company,
        name: val.Bereavement_name,
        mobile: val.Bereavement_mobile,
      },
      {
        id: val["Unit Trust_id"],
        insurance_type: "Unit Trust",
        company: val["Unit Trust_company"],
        name: val["Unit Trust_name"],
        mobile: val["Unit Trust_mobile"],
      },
    ];

    const finalPayload = [
      ...lifeContactPayload,
      ...generalContactPayload,
      ...oneContactPayload,
    ];

    const payload = {
      dnr: val?.dnr,
      donor_id_number: val?.donor_id_number,
      emergency_hospital: val?.emergency_hospital,
      has_insurance: val?.has_insurance,
      other_dnr: val?.dnr === "Other" ? val?.other_dnr : "",
      other_emergency_hospital:
        val?.emergency_hospital === "Other"
          ? val?.other_emergency_hospital
          : "",
      other_insurance: val?.other_insurance,
      emergency_agent_contacts: JSON.stringify(finalPayload),
    };

    await updateEmergency(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success("Emergency Information Updated Successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onFinishEmergency)}>
        <div className="next-of-kin">
          <div className="row mb-5">
            {formFields?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <FormGroup
                    item={item}
                    errors={errors}
                    register={register}
                    watch={watch}
                  />
                </Fragment>
              );
            })}
            <div className="col-12 mb-3">
              <div className="font-bold m-b-20">
                Emergency Contacts {"(Agents)"}
              </div>
              <div className="font-bold fs-14 m-b-15">Life Insurance</div>
              <div className="m-b-30">
                {lifeInsuranceContactList?.map((el, i) => (
                  <div className="row p-relative" key={`life-contact-row-${i}`}>
                    {el?.form_fields?.map((item, index) => (
                      <Fragment key={`life-contact-col-${index}`}>
                        <FormGroup
                          item={item}
                          errors={errors}
                          register={register}
                        />
                      </Fragment>
                    ))}
                    <div
                      className="delete-emergency-contact-btn primary-bg text-white"
                      onClick={() => verifyRemove(i, "Life")}
                    >
                      {isMobile ? "Remove Emergency Contact" : "x"}
                    </div>
                  </div>
                ))}
                <div className="add-emergency-contact-btn-container">
                  <div
                    className="add-emergency-contact-btn primary-bg text-white"
                    onClick={() => addEmergencyContact("Life")}
                  >
                    {isMobile ? "Add Life Insurance Contact" : "+"}
                  </div>
                </div>
              </div>
              <div className="font-bold fs-14 m-b-10">General Insurance</div>
              <div className="m-b-30">
                {generalInsuranceContactList?.map((el, i) => (
                  <div
                    className="row p-relative"
                    key={`general-contact-row-${i}`}
                  >
                    {el?.form_fields?.map((item, index) => (
                      <Fragment key={`general-contact-col-${index}`}>
                        <FormGroup
                          item={item}
                          errors={errors}
                          register={register}
                        />
                      </Fragment>
                    ))}
                    <div
                      className="delete-emergency-contact-btn primary-bg text-white"
                      onClick={() => verifyRemove(i, "General")}
                    >
                      {isMobile ? "Remove Emergency Contact" : "x"}
                    </div>
                  </div>
                ))}
                <div className="add-emergency-contact-btn-container">
                  <div
                    className="add-emergency-contact-btn primary-bg text-white"
                    onClick={() => addEmergencyContact("General")}
                  >
                    {isMobile ? "Add General Insurance Contact" : "+"}
                  </div>
                </div>
              </div>
              <div className="row p-relative">
                {emergencyContactFormFields?.map((item, index) => (
                  <Fragment key={`one-contact-${index}`}>
                    <FormGroup
                      item={item}
                      errors={errors}
                      register={register}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: `${isMobile ? "column" : "row"}`,
              gap: "10px",
            }}
          >
            <Button
              size="lg"
              disabled={props.loading}
              className={`${
                isMobile ? "primary-btn text-white" : "secondary-btn"
              }`}
              type="submit"
              style={{
                background: `${isMobile ? "#ed174e" : "unset"}`,
                padding: "10px 30px",
                fontSize: "16px",
                width: `${isMobile ? "100%" : "unset"}`,
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </Form>

      <Modal
        className="removeModal"
        show={isOpenRemoveModal}
        onHide={() => setIsOpenRemoveModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <h3 className="text-center m-b-30">
            Are you sure to remove the Emergency Contact?
          </h3>
          <div className="flex j-c-center flex-gap-20">
            <div
              className="secondary-common-button cursor-pointer"
              onClick={cancelRemove}
            >
              Cancel
            </div>
            <div
              className="primary-common-button cursor-pointer"
              onClick={removeEmergencyContact}
            >
              Remove
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
