import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ROOT } from "../configs/routeNames";
import { Provider } from "react-redux";
import store from "../redux/store";
import "../assets/styles/styles.scss";
import "react-widgets/styles.css";
import RoutingList from "./config/RoutingList";
import Home from "../app/pages/Home";
import Dashboard from "../app/pages/Dashboard";
import { checkPermission, isLoggedIn } from "./config/utils";
import { Toaster } from "react-hot-toast";

import Login from "../app/pages/Login";
import NoPage from "../app/modules/Login/NoPage";
import SignUp from "../app/pages/SignUp";

// import Pages from "./Pages";
import { TabTitle } from "../utils/GeneralFunction";
import ScrollToTop from "../utils/scrollToTop";

const compList = {
  Home,
  Dashboard,
};

const Root = () => {
  TabTitle("MedicAlert Foundation Malaysia");

  const PrivateRoute = ({ title, comp, permission }) => {
    const MyComponent = compList[title];
    if (permission) {
      return isLoggedIn() ? (
        <MyComponent Comp={comp} />
      ) : (
        <Navigate to="/home" />
      );
    } else {
      return <MyComponent Comp={comp} />;
    }
  };

  const routeComponents = RoutingList.map(
    ({ title, component, path, permission }, key) => (
      <Route
        path={path}
        element={
          <PrivateRoute
            title={title}
            comp={component}
            permission={permission}
          />
        }
        key={key}
      />
    )
  );

  return (
    <Provider store={store}>
      <Toaster
        toastOptions={{
          // success: {
          //   style: {
          //     background: 'green',
          //   },
          // },
          // error: {
          //   style: {
          //     background: 'red',
          //     color: 'white'
          //   },
          // },
        }}
      />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path={ROOT}
            element={
              isLoggedIn() ? (
                // <Navigate to="/dashboard" replace />
                <Navigate to="/account" />
              ) : (
                <Navigate to="/home" />
              )
            }
          />
          <Route path="/login" element={<Login Comp={"Login"} />} />
          <Route path="/sign-up" element={<SignUp Comp={"SignUp"} />} />
          <Route
            path="/forgot-password"
            element={<Login Comp={"ForgotPassword"} />}
          />
          <Route
            path="/reset-password"
            element={<Login Comp={"ResetPassword"} />}
          />
          {/* <Route path="/forgot-password" element={<Login Comp={'ForgotPassword'} />} />
          <Route path="/reset-password" element={<Login Comp={'OPTPCode'} />} /> */}
          {routeComponents}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Router>
    </Provider>
  );
};
export default Root;
