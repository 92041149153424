import { combineReducers } from 'redux';
import userReducer from '../../features/userSlice';
import global from 'Modules/Home/ducks/reducers';
import dashboard from 'Modules/Dashboard/ducks/reducers';
import { DESTROY_SESSION } from 'Modules/Home/ducks/constants';

const appReducer = combineReducers({
  user: userReducer,
  global,
  dashboard
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
