import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const updateProfile = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-profile`, payload);
};

export const updatePassword = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-password`, payload);
};

export const renewPlan = (payload) => {
  return axios.post(`${externalBaseUrl}/payments/renew-membership`, payload);
};

export const uploadProfilePicture = (file) => {
  let paylod = new FormData();
  paylod.append("file", file);

  return axios.post(`${externalBaseUrl}/users/upload-photo`, paylod, { "Content-Type": "multipart/form-data" });
};

export const updateNextOfKin = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-next-of-kin`, payload);
};

export const updateEmergency = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-emergency-details`, payload);
};

export const updateDoctorDetails = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-doctor-details`, payload);
};

export const updateConditions = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-condition-user`, payload);
};

export const updateAllergies = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-allergy-user`, payload);
};

export const updateMedicalCondition = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-medical-condition`, payload);
};

export const uploadResources = (file) => {
  let paylod = new FormData();
  paylod.append("file", file);

  return axios.post(`${externalBaseUrl}/users/upload-resources`, paylod, { "Content-Type": "multipart/form-data" });
};

export const removeResource = (payload) => {
  return axios.post(`${externalBaseUrl}/users/destroy-resource`, payload);
};

export const uploadWill = (file) => {
  let paylod = new FormData();
  paylod.append("file", file);

  return axios.post(`${externalBaseUrl}/users/upload-will`, paylod, { "Content-Type": "multipart/form-data" });
};

export const removeWill = () => {
  return axios.post(`${externalBaseUrl}/users/destroy-will`);
};

export const getUserDetails = (payload) => {
  return axios.get(`${externalBaseUrl}/users?${payload}`);
};

export const uploadReceipts = (file) => {
  let paylod = new FormData();
  paylod.append("file", file);

  return axios.post(`${externalBaseUrl}/users/upload-receipts`, paylod, { "Content-Type": "multipart/form-data" });
};

export const removeReceipts = (payload) => {
  return axios.post(`${externalBaseUrl}/users/destroy-receipt`, payload);
};
