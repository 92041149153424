import React, { Fragment, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDetails, renewPlan } from "../ducks/services";
import toast from "react-hot-toast";

import notValidLogo from "Assets/images/mob-footer-logo.png";
import { FileFilled } from "@ant-design/icons";

export default (props) => {
  const { medicalId, userReference } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const lifeInsuranceList = user?.emergency_agent_contacts?.filter(
    (el) => el.insurance_type === "Life"
  );
  const generalInsuranceList = user?.emergency_agent_contacts?.filter(
    (el) => el.insurance_type === "General"
  );
  const willList = user?.emergency_agent_contacts?.filter(
    (el) => el.insurance_type === "Will"
  );
  const trusteeList = user?.emergency_agent_contacts?.filter(
    (el) => el.insurance_type === "Trustee"
  );
  const unitTrustList = user?.emergency_agent_contacts?.filter(
    (el) => el.insurance_type === "Unit Trust"
  );
  const bereavementList = user?.emergency_agent_contacts?.filter(
    (el) => el.insurance_type === "Bereavement"
  );

  useEffect(() => {
    props.setLoading(true);

    getUserDetails(`id=${medicalId}&reference=${userReference}`)
      .then((res) => {
        if (res?.data?.code === 200) {
          // navigate("/");
          // toast.error(res?.data?.message ?? "Something went wrong");
          setUser(res?.data?.data);
          props.setLoading(false);
        } else {
          props.setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e.message ?? "Something went wrong");
        navigate("/");
      });
  }, []);

  const renewMembership = async () => {
    props.setLoading(true);

    const payload = {
      product_code: "RENEWAL_1Y",
      id: medicalId,
    };
    await renewPlan(payload)
      .then((res) => {
        props.setLoading(false);
        if (res.data.code === 200) {
          window.location.href = res.data.data.url;
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  const noData = "-";

  return (
    <div
      className={`user-medical-info ${!user ? "invalid" : ""} ${
        user?.is_expired ? "expired" : ""
      }`}
    >
      {props.loading ? (
        ""
      ) : (
        <>
          {!user && (
            <div className="medical-card">
              <div className="medical-card-header font-bold">
                MedicAlert ID: Not Valid
              </div>
              <div className="medical-card-body">
                <div className="logo-container">
                  <img src={notValidLogo} alt="medical alert logo" />
                </div>
              </div>
            </div>
          )}
          {user?.is_expired && (
            <div className="medical-card">
              <div className="medical-card-header font-bold">
                MedicAlert ID: {user?.id}
              </div>
              <div className="medical-card-body">
                <div className="font-bold primary-color">
                  Expired Date: {user?.expiry_date}
                </div>
                <Button
                  className="primary-btn text-center"
                  type="submit"
                  disabled={props.loading}
                  onClick={renewMembership}
                >
                  Expired, Renew Now
                </Button>
              </div>
            </div>
          )}
          {user && !user?.is_expired && (
            <>
              <div className="flex-col flex-gap-20">
                <div className="medical-card">
                  <div className="medical-card-header font-bold">
                    MedicAlert ID: {user?.username}
                  </div>
                  <div className="medical-card-body">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <div>{user?.name ?? noData}</div>
                        </Form.Group>
                      </div>
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>NRIC No</Form.Label>
                          <div>{user?.nric ?? noData}</div>
                        </Form.Group>
                      </div>
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Date of Birth</Form.Label>
                          <div>{user?.dob ?? noData}</div>
                        </Form.Group>
                      </div>
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Nationality</Form.Label>
                          <div>{user?.country_en ?? noData}</div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="medical-card">
                  <div className="medical-card-header font-bold">
                    Emergency Contact
                  </div>
                  <div className="medical-card-body">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <div>{user?.emergency_contact_name ?? noData}</div>
                        </Form.Group>
                      </div>
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Relationship</Form.Label>
                          <div>
                            {user?.emergency_contact_relationship ?? noData}
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Contact Mobile</Form.Label>
                          <a
                            style={{ display: "block" }}
                            href={`tel:${user?.emergency_contact_phone}`}
                          >
                            {user?.emergency_contact_phone ?? noData}
                          </a>
                        </Form.Group>
                      </div>

                      <hr />

                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <div>
                            {user?.second_emergency_contact_name ?? noData}
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Relationship</Form.Label>
                          <div>
                            {user?.second_emergency_contact_relationship ??
                              noData}
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Contact Mobile</Form.Label>
                          <a
                            style={{ display: "block" }}
                            href={`tel:${user?.second_emergency_contact_phone}`}
                          >
                            {user?.second_emergency_contact_phone ?? noData}
                          </a>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="medical-card">
                  <div className="medical-card-header font-bold">
                    Medical Record
                  </div>
                  <div className="medical-card-body">
                    <div className="row">
                      {user?.blood_type && (
                        <div className={`col-12 mb-3`}>
                          <Form.Group className="mb-3">
                            <Form.Label>Blood Type</Form.Label>
                            <div>{user?.blood_type}</div>
                          </Form.Group>
                        </div>
                      )}
                      {user?.condition_users?.length !== 0 && (
                        <div className={`col-12 mb-3`}>
                          <Form.Group className="mb-3">
                            <Form.Label>Medical Conditions</Form.Label>
                            {user?.condition_users && (
                              <ol>
                                {user?.condition_users?.map((el, i) => (
                                  <li key={`medical-cond-${i}`}>
                                    {el.condition_code === "OTHER"
                                      ? el.desc_other
                                      : el.condition_desc}
                                  </li>
                                ))}
                              </ol>
                            )}
                          </Form.Group>
                        </div>
                      )}
                      {user?.allergy_users?.length !== 0 && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Allergies</Form.Label>
                            {user?.allergy_users && (
                              <ol>
                                {user?.allergy_users?.map((el, i) => (
                                  <li key={`allergy-cond-${i}`}>
                                    {el.allergy_code === "OTHER"
                                      ? el.desc_other
                                      : el.allergy_desc}
                                  </li>
                                ))}
                              </ol>
                            )}
                          </Form.Group>
                        </div>
                      )}
                      {user?.medical_remarks && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Medication</Form.Label>
                            <div>{user?.medical_remarks}</div>
                          </Form.Group>
                        </div>
                      )}
                      {user?.resources?.length !== 0 && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Medical Report</Form.Label>
                            {user?.resources?.map((el, i) => (
                              <a
                                className="flex flex-gap-10"
                                href={el.url}
                                key={`medical-report-${i}`}
                              >
                                <FileFilled style={{ color: "#777" }} />{" "}
                                {el.filename}
                              </a>
                            ))}
                          </Form.Group>
                        </div>
                      )}
                      {user?.doctor_details?.length !== 0 && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Doctor's Details</Form.Label>
                            {user?.doctor_details?.map((el, i) => (
                              <div className="mb-3" key={`doctor-details-${i}`}>
                                <div>{el.name}</div>
                                <a
                                  style={{ display: "block" }}
                                  href={`tel:${el.phone}`}
                                >
                                  {el.phone}
                                </a>
                                <div>{el.treatment}</div>
                              </div>
                            ))}
                          </Form.Group>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="medical-card">
                <div className="medical-card-header font-bold">
                  Emergency Action Plan
                </div>
                <div className="medical-card-body">
                  <div className="row">
                    {user?.dnr && (
                      <div className={`col-12 mb-3`}>
                        <Form.Group className="mb-3">
                          <Form.Label>DNR {"(Do Not Resuscitate)"}</Form.Label>
                          <div>{user?.donor_id_number ? "Yes" : user?.dnr}</div>
                        </Form.Group>
                      </div>
                    )}
                    <div className={`col-12 mb-3`}>
                      <Form.Group className="mb-3">
                        <Form.Label>Organ Donor</Form.Label>
                        {user?.donor_id_number && (
                          <>
                            <div>Yes</div>
                            <div>ID No: {user?.donor_id_number ?? noData}</div>
                          </>
                        )}
                        {!user?.donor_id_number && <div>No</div>}
                      </Form.Group>
                    </div>
                    {lifeInsuranceList?.length !== 0 && (
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>Life Insurance</Form.Label>
                          {lifeInsuranceList?.map((el, i) => (
                            <div className="m-b-20" key={`life-insurance-${i}`}>
                              <div>{el.company}</div>
                              <div>Agent's Name: {el.name}</div>
                              <div>
                                Mobile No:{" "}
                                <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                              </div>
                            </div>
                          ))}
                        </Form.Group>
                      </div>
                    )}
                    {generalInsuranceList?.length !== 0 && (
                      <div className="col-12 mb-3">
                        <Form.Group className="mb-3">
                          <Form.Label>General Insurance</Form.Label>
                          {generalInsuranceList?.map((el, i) => (
                            <div className="m-b-20" key={`life-insurance-${i}`}>
                              <div>{el.company}</div>
                              <div>Agent's Name: {el.name}</div>
                              <div>
                                Mobile No:{" "}
                                <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                              </div>
                            </div>
                          ))}
                        </Form.Group>
                      </div>
                    )}
                    {willList?.length !== 0 &&
                      (willList?.[0]?.company ||
                        willList?.[0]?.name ||
                        willList?.[0]?.mobile) && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Will</Form.Label>
                            {willList?.map((el, i) => (
                              <Fragment key={`life-insurance-${i}`}>
                                <div>{el.company}</div>
                                <div>
                                  Agent's Name: {el.name ? el.name : "-"}
                                </div>
                                <div>
                                  Mobile No:{" "}
                                  {el.mobile && (
                                    <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                                  )}
                                  {!el.mobile && "-"}
                                </div>
                              </Fragment>
                            ))}
                          </Form.Group>
                        </div>
                      )}
                    {trusteeList?.length !== 0 &&
                      (trusteeList?.[0]?.company ||
                        trusteeList?.[0]?.name ||
                        trusteeList?.[0]?.mobile) && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Trustee</Form.Label>
                            {trusteeList?.map((el, i) => (
                              <Fragment key={`life-insurance-${i}`}>
                                <div>{el.company}</div>
                                <div>
                                  Agent's Name: {el.name ? el.name : "-"}
                                </div>
                                <div>
                                  Mobile No:{" "}
                                  <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                                </div>
                              </Fragment>
                            ))}
                          </Form.Group>
                        </div>
                      )}
                    {unitTrustList?.length !== 0 &&
                      (unitTrustList?.[0]?.company ||
                        unitTrustList?.[0]?.name ||
                        unitTrustList?.[0]?.mobile) && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Unit Trust</Form.Label>
                            {unitTrustList?.map((el, i) => (
                              <Fragment key={`unit-trust-${i}`}>
                                <div>{el.company}</div>
                                <div>
                                  Agent's Name: {el.name ? el.name : "-"}
                                </div>
                                <div>
                                  Mobile No:{" "}
                                  <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                                </div>
                              </Fragment>
                            ))}
                          </Form.Group>
                        </div>
                      )}
                    {bereavementList?.length !== 0 &&
                      (bereavementList?.[0]?.company ||
                        bereavementList?.[0]?.name ||
                        bereavementList?.[0]?.mobile) && (
                        <div className="col-12 mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label>Bereavement</Form.Label>
                            {bereavementList?.map((el, i) => (
                              <Fragment key={`bereavement-${i}`}>
                                <div>{el.company}</div>
                                <div>
                                  Agent's Name: {el.name ? el.name : "-"}
                                </div>
                                <div>
                                  Mobile No:{" "}
                                  <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                                </div>
                              </Fragment>
                            ))}
                          </Form.Group>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
