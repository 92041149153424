const titles = {
  DASHBOARD: "Dashboard",
};

export const Dashboard = [
  {
    component: "Account",
    path: "/account",
    title: titles.DASHBOARD,
    key: "account",
    // menu: 'CRM',
    // submenu: 'Customer',
    // icon: 'OverviewIcon',
    parent: false,
    permission: true,
  },
  {
    component: "Profile",
    path: "/profile",
    title: titles.DASHBOARD,
    key: "profile",
    // menu: 'CRM',
    // submenu: 'Customer',
    // icon: 'OverviewIcon',
    parent: false,
    permission: true,
  },
  {
    component: "Payment",
    path: "/payment",
    title: titles.DASHBOARD,
    key: "payment",
    // menu: 'CRM',
    // submenu: 'Customer',
    // icon: 'OverviewIcon',
    parent: false,
    permission: true,
  },
  {
    component: "Faq",
    path: "/faq",
    title: titles.DASHBOARD,
    key: "faq",
    // menu: 'CRM',
    // submenu: 'Customer',
    // icon: 'OverviewIcon',
    parent: false,
    permission: false,
  },
  {
    component: "Privacy",
    path: "/privacy",
    title: titles.DASHBOARD,
    key: "privacy",
    parent: false,
    permission: false,
  },
  {
    component: "UserMedicalInfo",
    path: "/:medicalId/:userReference",
    title: titles.DASHBOARD,
    key: "userMedicalInfo",
    permission: false,
    parent: false,
  },
];
