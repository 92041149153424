import React, { useEffect, useState } from "react";
import HomeLayout from "../../templates/HomeLayout";
import Home from "Modules/Home";
import About from "Modules/Home/About";
import Membership from "Modules/Home/Membership";
import HealthProfessionals from "Modules/Home/HealthProfessionals";
import Donate from "Modules/Home/Donate";
import Contact from "Modules/Home/Contact";
import CodeConduct from "Modules/Home/CodeConduct";
import TrainingSupport from "Modules/Home/TrainingSupport";
import PostageShipping from "Modules/Home/PostageShipping";
import Endorsement from "Modules/Home/Endorsement";
import MeetOurBoard from "Modules/Home/MeetOurBoard";
import Testimonials from "Modules/Home/Testimonials";
import SignUp from "Modules/Home/SignUp";
import NotFound from "Modules/Home/NotFound";
import Checkout from "Modules/Home/Checkout";
import { GlobalStateProvider } from "../../context/GlobalStateProvider";

const Components = {
  Home,
  About,
  Membership,
  HealthProfessionals,
  Donate,
  Contact,
  CodeConduct,
  TrainingSupport,
  PostageShipping,
  Endorsement,
  MeetOurBoard,
  Testimonials,
  SignUp,
  Checkout,
  NotFound,
};

export default (props) => {
  const HomeComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <GlobalStateProvider>
      <HomeLayout loading={loading}>
        <HomeComp loading={loading} setLoading={setLoading} />
      </HomeLayout>
    </GlobalStateProvider>
  );
};
