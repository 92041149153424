import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../ducks/actions";
import { Tab, Tabs } from "react-bootstrap";
import * as TabCards from "./tabList";
import { useGlobalState } from "../../../context/GlobalStateProvider";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default (props) => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userid");
  const user = useSelector((state) => state.dashboard.user);
  const meta = useSelector((state) => state.dashboard.userOption);
  const dispatch = useDispatch();
  // Responsive
  const { isMobile } = useGlobalState();

  const tabs = [
    {
      key: "detail",
      title: "My Profile",
      Comp: "Detail",
    },
    {
      key: "nextofkin",
      title: "Emergency Contact",
      Comp: "NextOfKin",
    },
    {
      key: "emergency",
      title: "Emergency Action Plan",
      Comp: "Emergency",
    },
    {
      key: "medical",
      title: "Medical Condition",
      Comp: "Medical",
    },
    {
      key: "passwordsetting",
      title: "Password Setting",
      Comp: "PasswordSetting",
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("is_new_user") == "1") {
      toast.error("Access Denied.");
      navigate("/account");
    } else {
      callApi();
    }
  }, []);

  const callApi = () => {
    dispatch(getUser(userId, props.setLoading));
  };

  return (
    <div className="profile-container">
      <div className="card">
        <div className="card-body">
          <Tabs
            defaultActiveKey="detail"
            id="fill-tab-example"
            className="mb-5 eq-size"
          >
            {tabs.map((item, index) => {
              const Cardi = TabCards[item.Comp];
              return (
                <Tab eventKey={item.key} title={item.title} key={index}>
                  <Cardi
                    {...props}
                    user={user}
                    meta={meta}
                    isMobile={isMobile}
                    reloadApi={callApi}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
