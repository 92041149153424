import React, { useState, useEffect, createContext, useContext } from "react";

// Create a context for the global state
const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const isMobile = browserWidth <= 768;

  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this effect only runs once on mount

  return (
    <GlobalStateContext.Provider value={{ browserWidth, isMobile }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook to use the global state
export const useGlobalState = () => useContext(GlobalStateContext);
