import React, { useEffect } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../../../routing/config/utils';
import { useDispatch } from "react-redux";
import { login } from "Features/userSlice";

function Checkout(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(location.search);
  const isSuccess = queryParameters.get('success');
  const isRenew = queryParameters.get('r');
  const isReplacement = queryParameters.get('rpl');
  const isDonation = queryParameters.get('d');

  useEffect(() => {
    if (isLoggedIn && isRenew === "0") {
      setTimeout(() => {
        let email = localStorage.getItem("email")
        let username = localStorage.getItem("userName")
        dispatch(
          login({
            username: email || username,
          })
        );
        navigate("/account");
      }, 5000);
    }
  }, [])

  return (
    <div className="my-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5">
          <div className="card common h-100" style={{ minHeight: '50vh' }}>
            <div className="card-body">
              {
                isSuccess === "1" ?
                <>
                  {
                    isRenew === "0" && isReplacement === "0"
                    ?
                      <>
                        <h3 className="text-center c-success mb-2">
                          Successfully Subcribed New Membership, please proceed to login.
                        </h3>
                        <p>Login details have been sent to your mailbox.</p>
                        <p>Auto redirecting to account page..</p>
                        <Link to="/login"><u>Click here if redirection is not working..</u></Link>
                      </>
                    : isRenew === "0" && isReplacement === "1"
                    ?
                       <>
                        <h3 className="text-center c-success mb-2">
                          Successfully paid for replacement.
                        </h3>
                        <Link to="/account">Back To Account</Link>
                      </>
                    : isRenew === "1"
                    ?
                      <>
                        <h3>
                          Successfully Renewed Membership.
                        </h3>
                        <Link to="/account">Back To Account</Link>
                      </>
                    : isDonation === "1"
                    ?
                      <>
                        <h3>
                          Donation Successful.
                        </h3>
                        <p>
                          Thank you for your donation payment.
                        </p>
                        <Link to="/membership">Back</Link>
                      </>
                    :
                      <>
                        <h3 className="text-center c-success mb-2">
                          Successfully Subcribed New Membership, please proceed to login.
                        </h3>
                        <p>Login details have been sent to your mailbox.</p>
                        <p>Auto redirecting to account page..</p>
                        <Link to="/login"><u>Click here if redirection is not working..</u></Link>
                      </>
                  }
                </>
                :
                <>
                  <h3 className="text-center c-danger mb-2">Payment failed</h3>
                  {
                    isRenew === "0"
                    ?
                      <>
                        <p>Login details have been sent to your mailbox. Please make a payment to enjoy full access.</p>
                        <p>Auto redirecting to account page..</p>
                        <Link to="/membership"><u>Click here if redirection is not working..</u></Link>
                      </>
                    :
                      <Link to="/account">{"<"} Back</Link>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
