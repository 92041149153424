import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { updateMedicalCondition } from "../../../../../ducks/services";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";

export default (props) => {
  const { user, isMobile } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const formFields = [
    {
      name: "medical_remarks",
      label: "Medications",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Medications is Required",
      size: "lg"
    },
    {
      name: "blood_type",
      label: "Blood Group",
      placeholder: "Please select",
      type: "select",
      options: ["A+ ve", "A- ve", "B+ ve", "B- ve", "AB+ ve", "AB- ve", "O+ ve", "O- ve", "Not known"],
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Blood Type is Required",
      size: "lg"
    },
    {
      name: "extra_medical_info",
      label: "Additional Medical Information",
      as: "textarea",
      req: false,
      class: "col-12",
      rows: "4",
      reqmsg: "Additional Medical Information is Required",
      size: "lg"
    },
  ];

  useEffect(() => {
    if (user) {
      setValue("blood_type", user.blood_type);
      setValue("medical_remarks", user.medical_remarks);
      setValue("extra_medical_info", user.extra_medical_info);
    }
  }, [user]);

  const onFinishDoctorDetails = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val
    };

    await updateMedicalCondition(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success("Medical Information Updated Successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <Form onSubmit={handleSubmit(onFinishDoctorDetails)}>
      <div className="next-of-kin">
        <div className="row mb-5">
          {
            formFields?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <FormGroup item={item} errors={errors} register={register} />
                </Fragment>
              )
            })
          }
        </div>
        <div className="row justify-content-end">
          <div className={`col-${isMobile ? "12" : "6"} mb-3 align-self-center text-right`}>
            <Button
              size="lg"
              disabled={props.loading}
              className={`${isMobile ? "primary-btn text-white" : "secondary-btn"}`}
              type="submit"
              style={{
                background: `${isMobile ? "#ed174e" : "unset"}`,
                width: "100%",
                maxWidth: `${isMobile ? "100%" : "268px"}`,
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
};