import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoginLogo from "Assets/images/medical-alert-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "Services/axiosInterceptor";
import { externalBaseUrl } from "../../../configs/constants";
import toast from "react-hot-toast";

function ForgotPassword(props) {
  const navigate = useNavigate();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onFinish = (values) => {
    props.setLoading(true);
    axios
      .post(`${externalBaseUrl}/users/forgot-password`, {
        email: values.username,
      })
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          navigate("/login");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <Form className="login-page" onSubmit={handleSubmit(onFinish)}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-5">
          <div className="card login-form form">
            <div className="card-body">
              <div className="row">
                <div className="col-12 text-center mb-5 d-dev">
                  <img src={LoginLogo} alt="Login" className="w-50" />
                </div>
                <div className="col-12 text-center">
                  <h2 className="mb-4">Forgot Password</h2>
                  <p className="text-secondary">
                    Please enter existing MedicAlert email
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  <Form.Group controlId="username" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      isInvalid={Boolean(errors.username)}
                      {...register("username", {
                        required: true,
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.username?.type === "required" &&
                        "Email is required"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center text-secondary create-account-wrapper">
                  Forget your email address? Please contact{" "}
                  <a
                    href="https://wa.me/60184710020"
                    className="font-bold primary-color"
                    target="_blank"
                  >
                    Customer Service
                  </a>
                </div>
              </div>
              <div className="row my-5 justify-content-center">
                <div className="col-9 login-btn-wrapper">
                  <Button
                    className="primary-btn text-center w-100"
                    type="submit"
                    disabled={props.loading}
                  >
                    {props.loading ? <Spinner animation="border" /> : "Send"}
                  </Button>
                </div>
                <div className="col-12 text-center mt-5 text-secondary create-account-wrapper">
                  If you don’t have one, click{" "}
                  <Link className="font-bold primary-color" to="/membership">
                    Create Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default ForgotPassword;
