import React from "react";

const faqList = [
  {
    question: "What is an NFC Medical ID wristband and pendant?",
    answer:
      "It is a wearable device that uses NFC technology to store and provide instant access to critical medical information.",
  },
  {
    question: "How does NFC technology work in these devices?",
    answer:
      "NFC (Near Field Communication) allows the wristband and pendant to communicate with compatible devices, such as smartphones, to quickly share stored medical information.",
  },
  {
    question:
      "What types of medical information can be stored on the NFC Medical ID?",
    answer:
      "This NFC Medical ID can store a comprehensive range of medical records, including allergies, medical conditions, medications, emergency contacts, medical history, blood type, vaccination records, insurance agent's contact details, and will & trust agent's contact details.",
  },
  {
    question: "Is the information stored on the NFC chip secure?",
    answer:
      "Yes, the information is protected by robust encryption, ensuring it is secure.",
  },
  {
    question: "How do I update the medical information on my NFC Medical ID?",
    answer:
      "You can update the information using a compatible smartphone or computer with the appropriate app or software provided by the manufacturer.",
  },
  {
    question: "Is the NFC Medical ID wristband comfortable to wear?",
    answer:
      "Yes, it is made from high-quality, hypoallergenic materials designed for comfort, even for those with sensitive skin.",
  },
  {
    question: "Can the NFC Medical ID be worn in all environments?",
    answer:
      "Yes, it is designed to withstand various environmental conditions, including water and physical wear and tear.",
  },
  {
    question: "Who can benefit from wearing an NFC Medical ID?",
    answer:
      "Anyone with medical conditions, or allergies, or those who want their medical information readily accessible in emergencies can benefit from wearing it.",
  },
  {
    question:
      "How do emergency responders access the information on the NFC Medical ID?",
    answer:
      "Emergency responders can use an NFC-enabled device, such as a smartphone, to scan the wristband or pendant and access the stored information.",
  },
  {
    question: "Can the NFC Medical ID be used internationally?",
    answer:
      "Yes, the information stored on the NFC Medical ID can be accessed globally, making it useful for international travel.",
  },
  {
    question: "How durable is the NFC Medical ID wristband and pendant?",
    answer:
      "Both the wristband and pendant are made from high-quality materials designed to be durable and long-lasting.",
  },
  {
    question:
      "Is there a subscription fee for using the NFC Medical ID service?",
    answer:
      "Yes, the annual subscription fee of MedicAlert Foundation Malaysia also covers cloud storage and updates.",
  },
  {
    question:
      "How can I ensure my medical information is always up to date on the NFC Medical ID?",
    answer:
      "To ensure your medical information is always up to date, members can log in to www.medicalert.org.my, navigate to your profile, and make the necessary changes to your medical details. Save the updates, and the website will automatically sync the new information with your NFC device. Verify the update by scanning your NFC wristband or pendant with a compatible device to ensure the latest data is available.",
  },
  {
    question: "What if I lose my NFC Medical ID?",
    answer:
      "Report the loss to MedicAlert Customer Service (+60184710020). We will deactivate the lost device and guide you through the process of getting a replacement and transfer the information to a new one.",
  },
  {
    question: "Can multiple family members have their own NFC Medical IDs?",
    answer:
      "Yes, each family member can have their own NFC Medical ID with their unique medical information.",
  },
  {
    question: "Are there different styles of NFC Medical IDs available?",
    answer:
      "Yes, they come in various styles, including silicon wristbands and metal pendants, to suit different preferences.",
  },
  {
    question: "How do I clean and maintain my NFC Medical ID?",
    answer:
      "Clean it with mild soap and water. Avoid harsh chemicals that could damage the materials and the NFC chip.",
  },
  {
    question: "Can the NFC Medical ID be used by children?",
    answer:
      "Yes, it is suitable for children, ensuring their medical information is accessible in emergencies.",
  },
  {
    question: "How long does the NFC chip last?",
    answer:
      "The NFC chip is designed to last for several years with proper care and maintenance.",
  },
  {
    question: "How do I get an NFC Medical ID wristband or pendant for myself?",
    answer:
      "Every donation of RM250 will entitle you to a one-year MedicAlert membership subscription, which comes with an NFC Medical ID wristband and pendant. For each subsequent year, you can donate RM200 for subscription renewal.",
  },
  {
    question:
      "Do I need to provide any supporting documents to upload my medical history to this medical ID?",
    answer:
      "Yes, you need to upload supporting documents issued by a registered and certified doctor. Another option is to fill in the MedicAlert Application Form that is available here. Make sure all documents are signed by the doctor and stamped by the clinic or hospital.",
  },
  {
    question:
      "What happens if I key in the wrong information during registration?",
    answer:
      "There will be a second verification done by our group of doctors at MedicAlert to ensure the spelling and information match the supporting documents that are being uploaded by members. However, members are fully responsible for all the data entered into the system, and MedicAlert Foundation Malaysia will not be liable for any incidents resulting from incorrect information.",
  },
];

export default (props) => {
  return (
    <div className="faq">
      <div className="row mb-5">
        <div className="col-12">
          <h3 className="text-primary">
            Top 20 Q&A for the NFC Medical ID Wristband and Pendant
          </h3>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12 mb-4">
          <div className="card common">
            <div className="card-body">
              {faqList.map((faq, i) => (
                <div className="mb-2" key={`faq-${i}`}>
                  <h3 className="text-primary">
                    {`${i + 1}.`} {faq.question}
                  </h3>
                  <p className="text-secondary">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
