import React, { useState, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { uploadWill, removeWill } from "../../../ducks/services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

export default (props) => {
  const { user, reloadApi } = props;
  const [data, setData] = useState(null);
  const {
    register,
    setValue,
  } = useForm();

  useEffect(() => {
    if (user) {
      setData(user.will);
    }
  }, [user]);

  const handleChange = async (event) => {
    props.setLoading(true);

    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      try {
        await uploadWill(fileUploaded);
        props.setLoading(false);
        setValue("uploadfile", null);
        reloadApi();
      } catch (error) {
        props.setLoading(false);
        toast.error(`Error during file upload: ${error}`);
      }
    }
  };

  const onRemove = async () => {
    props.setLoading(true);

    await removeWill().then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        toast.success("File removed successfully");
        reloadApi();
      } else {
        toast.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      toast.error(`Error during file removal: ${e.message}`);
    })
  }

  return (
    <div className="next-of-kin">
      <div className="row">
        <div className="col-12">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              Keeper of your Will/Wasiat. <br/>You can upload a copy of your will here and inform your next of kin about it. <br/>
              Medicalert just act as a storage for a copy of the will that you have written with a registered will writer.
            </Form.Label>
            <Form.Control size="lg" type="file" accept="image/*,.pdf" {...register("uploadfile")} onChange={handleChange} />
          </Form.Group>
        </div>

        {
          data && data?.id &&
          <div className="col-12 mt-5">
            <Table hover>
              <thead>
                <tr>
                  <th>Filename</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href={data?.url} target="_blank">{data?.filename}</a>
                  </td>
                  <td>
                     <a href="#" onClick={() => onRemove()}>
                      Remove
                     </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        }
      </div>
    </div>
  )
};