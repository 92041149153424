import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { ChevronDown } from "react-bootstrap-icons";

import resourcesBanner from "Assets/images/resources/resources-banner.png";
import postageImg from "Assets/images/resources/postage-img.png";
import returnImg from "Assets/images/resources/return-img.png";
import postageIcon from "Assets/images/resources/postage-icon.svg";
import returnIcon from "Assets/images/resources/return-icon.svg";
import BottomContainer from "../../../components/common/SectionBottom/BottomContainer";
import BottomHeader from "../../../components/common/SectionBottom/BottomHeader";
import BottomBody from "../../../components/common/SectionBottom/BottomBody";
import BottomFooter from "../../../components/common/SectionBottom/BottomFooter";

import Banner from "../../../components/common/Banner";

import { useGlobalState } from "../../../context/GlobalStateProvider";

const headerAccordion = [
  {
    title: "MyResQ",
    body: "Medicalert is partnering with MyResQ to save the lives of those who suffer from out-of-hospital cardiac arrest and other medical emergencies. MyResQ app, when activated, will inform rescuers with CPR/AED skills who are within 500 meters of the victim to rush to the victim to perform resuscitation procedures before the critical 4.5 minutes while waiting for the ambulance service and paramedics to arrive. To download the app, please go <a href='https://web.myresq.my/' target='_blank'>HERE</a>.",
  },
  {
    title: "Training MyResQ",
    body: "MyResQ provides HRDF-approved CPR/AED-certified courses for individuals and companies. To enrol in the course please go <a href='https://web.myresq.my/' target='_blank'>HERE</a>.",
  },
  {
    title:
      "How does MedicAlert provide emergency medical information to MyResQ?",
    body: `<div className="header-right-content">
    <div>
      There are several levels of medical information that can
      assist MyResQ when treating an individual. The most
      critical, top-line information available to MyResQ
      is on the custom engraved medical ID. Beyond that, the
      individual’s full medical history can be obtained by
      contacting our 24/7 emergency response service.
    </div>
    <br />
    <div>
      Our 24/7 emergency response service uses a trained team of
      specialists, with experience in emergency medical professions.
      When a MyResQ identifies a MedicAlert ID in the form
      of a jewelry tag or wallet card and contacts our team, we act
      as the liaison between the MyResQ and the individual
      in need of care. 24 hours a day, 7 days a week, our team
      relays the information maintained in our members’ digital
      health records to expedite care for the person in need. Our
      team is trained to communicate with MyResQ, doctors,
      nurses and other emergency professionals. They can provide our
      members’ complete medical profile and history to the receiving
      medical facility. They’ll also connect the MyResQ
      with the member’s family or other emergency contacts to
      improve outcomes anytime a member is unable to communicate.
    </div>
    <br />
    <div>
      A MedicAlert member’s digital health record contains the
      following medical history and information that is relayed to
      emergency response professionals:
    </div>
    <br />
    <ul>
      <li>Medical conditions</li>
      <li>Medications</li>
      <li>Allergies</li>
      <li>Implanted devices</li>
      <li>Surgeries</li>
      <li>Vaccinations</li>
      <li>Organ donor</li>
      <li>Advance Directives</li>
      <li>Emergency contacts</li>
    </ul>
  </div>`,
  },
];

export default function Resources(props) {
  return (
    <div className="health-pro-page">
      {/* Header */}
      <section className="top-title">
        <div className="primary-color">Collaboration Partners</div>
      </section>

      {/* Banner */}
      <Banner webBanner={resourcesBanner} />

      {/* Header */}
      <section className="section-header">
        <div className="container">
          <div className="header-container resource-header-container">
            <div className="header-left resource-header-left">
              <div className="font-bold">
                Resources For <span className="primary-color">MyResQ</span>
              </div>
              <div className="font-bold primary-color">
                & Health Professionals
              </div>
            </div>
            <div className="header-right d-dev">
              <div className="header-right-title">
                <h1 className="font-bold">MyResQ</h1>
              </div>
              <div className="header-right-content">
                Medicalert is partnering with MyResQ to save the lives of those
                who suffer from out-of-hospital cardiac arrest and other medical
                emergencies. MyResQ app, when activated, will inform rescuers
                with CPR/AED skills who are within 500 meters of the victim to
                rush to the victim to perform resuscitation procedures before
                the critical 4.5 minutes while waiting for the ambulance service
                and paramedics to arrive. To download the app, please go{" "}
                <a href="https://web.myresq.my/" target="_blank">
                  HERE
                </a>
                .
              </div>

              <br />
              <br />

              <div className="header-right-title">
                <h1 className="font-bold">Training MyResQ</h1>
              </div>
              <div className="header-right-content">
                MyResQ provides HRDF-approved CPR/AED-certified courses for
                individuals and companies. To enrol in the course please go{" "}
                <a href="https://web.myresq.my/" target="_blank">
                  HERE
                </a>
                .
              </div>

              <br />
              <br />

              <div className="header-right-title">
                <h1 className="font-bold">
                  How does MedicAlert provide emergency medical information to
                  MyResQ?
                </h1>
              </div>
              <div className="header-right-content">
                <div>
                  There are several levels of medical information that can
                  assist MyResQ when treating an individual. The most critical,
                  top-line information available to MyResQ is on the custom
                  engraved medical ID. Beyond that, the individual’s full
                  medical history can be obtained by contacting our 24/7
                  emergency response service.
                </div>
                <br />
                <div>
                  Our 24/7 emergency response service uses a trained team of
                  specialists, with experience in emergency medical professions.
                  When a MyResQ identifies a MedicAlert ID in the form of a
                  jewelry tag or wallet card and contacts our team, we act as
                  the liaison between the MyResQ and the individual in need of
                  care. 24 hours a day, 7 days a week, our team relays the
                  information maintained in our members’ digital health records
                  to expedite care for the person in need. Our team is trained
                  to communicate with MyResQ, doctors, nurses and other
                  emergency professionals. They can provide our members’
                  complete medical profile and history to the receiving medical
                  facility. They’ll also connect the MyResQ with the member’s
                  family or other emergency contacts to improve outcomes anytime
                  a member is unable to communicate.
                </div>
                <br />
                <div>
                  A MedicAlert member’s digital health record contains the
                  following medical history and information that is relayed to
                  emergency response professionals:
                </div>
                <br />
                <ul>
                  <li>Medical conditions</li>
                  <li>Medications</li>
                  <li>Allergies</li>
                  <li>Implanted devices</li>
                  <li>Surgeries</li>
                  <li>Vaccinations</li>
                  <li>Organ donor</li>
                  <li>Advance Directives</li>
                  <li>Emergency contacts</li>
                </ul>
              </div>
            </div>
            <div className="header-right m-dev">
              <Accordion defaultActiveKey="0">
                {headerAccordion?.map((item, index) => {
                  return (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body
                        className="text-secondary"
                        dangerouslySetInnerHTML={{ __html: item.body }}
                      ></Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function FeatureBox({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useGlobalState();

  useEffect(() => {
    if (isMobile) setIsOpen(item.index === 0 ? true : false);
    else setIsOpen(false);
  }, [isMobile]);

  const handleOpen = () => {
    if (!isMobile) return;
    setIsOpen((isOpen) => !isOpen);
  };

  const contentStyle = {
    maxHeight: isOpen ? "500px" : "7.2rem",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  };

  return (
    <div
      className={`feature-box resource-box ${
        item.index === 0 ? "left" : "right"
      } ${isOpen ? "open" : ""}`}
      style={isMobile ? contentStyle : {}}
    >
      <div className="m-dev">
        <div
          className={`feature-box-header flex al-i-c ${
            isOpen ? "ju-c-end" : "ju-c-sb"
          }`}
          onClick={handleOpen}
        >
          {!isOpen && (
            <div className="primary-color font-bold">{item.title}</div>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.599"
            height="9"
            viewBox="0 0 16.599 9"
          >
            <path
              id="Polygon_31"
              data-name="Polygon 31"
              d="M8.3,0l8.3,9H0Z"
              transform="translate(16.599 9) rotate(180)"
              fill="#061528"
            />
          </svg>
        </div>
      </div>
      <div className="feature-box-body">
        <div className="feature-img text-center">
          <img src={item.icon} alt={item.title} />
        </div>
        <div className="feature-desc">
          <div className="feature-desc-title primary-color font-bold mb-3">
            {item.title}
          </div>
          <div className="feature-desc-body secondary-color">
            {item.content}
          </div>
        </div>
      </div>
    </div>
  );
}
