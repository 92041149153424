import { Dashboard } from "./Dashboard";

const titles = {
  HOME: "Home",
  ABOUT: "About",
};

const routes = [
  {
    component: "NotFound",
    path: "/404",
    title: titles.HOME,
    menu: "404",
    parent: false,
    permission: false,
  },
  {
    component: "Home",
    path: "/home",
    title: titles.HOME,
    key: "home",
    permission: false,
    // menu: 'Dashboard',
    // icon: 'DashboardIcon2',
    parent: false,
  },
  {
    component: "About",
    path: "/about",
    title: titles.HOME,
    key: "about",
    permission: false,
    // menu: 'Dashboard',
    // icon: 'DashboardIcon2',
    parent: false,
  },
  {
    component: "Membership",
    path: "/membership",
    title: titles.HOME,
    key: "membership",
    permission: false,
    // menu: 'Dashboard',
    // icon: 'DashboardIcon2',
    parent: false,
  },
  {
    component: "HealthProfessionals",
    path: "/collaboration-partners",
    title: titles.HOME,
    key: "collaboration-partners",
    permission: false,
    // menu: 'Dashboard',
    // icon: 'DashboardIcon2',
    parent: false,
  },
  {
    component: "Donate",
    path: "/donate",
    title: titles.HOME,
    key: "donate",
    permission: false,
    // menu: 'Dashboard',
    // icon: 'DashboardIcon2',
    parent: false,
  },
  {
    component: "Contact",
    path: "/contact",
    title: titles.HOME,
    key: "contact",
    permission: false,
    // menu: 'Dashboard',
    // icon: 'DashboardIcon2',
    parent: false,
  },
  {
    component: "CodeConduct",
    path: "/code-of-conduct",
    title: titles.HOME,
    key: "code-conduct",
    permission: false,
    parent: false,
  },
  {
    component: "TrainingSupport",
    path: "/training-&-support",
    title: titles.HOME,
    key: "training-&-support",
    permission: false,
    parent: false,
  },
  {
    component: "PostageShipping",
    path: "/postage-&-shipping",
    title: titles.HOME,
    key: "postage-&-shipping",
    permission: false,
    parent: false,
  },
  {
    component: "Endorsement",
    path: "/endorsement",
    title: titles.HOME,
    key: "endorsement",
    permission: false,
    parent: false,
  },
  {
    component: "MeetOurBoard",
    path: "/meet-our-board",
    title: titles.HOME,
    key: "meet-our-board",
    permission: false,
    parent: false,
  },
  {
    component: "Testimonials",
    path: "/testimonials",
    title: titles.HOME,
    key: "testimonials",
    permission: false,
    parent: false,
  },
  {
    component: "Checkout",
    path: "/checkout",
    title: titles.HOME,
    key: "checkout",
    permission: false,
    parent: false,
  },

  ...Dashboard,
];

export default routes;
