import React from "react";
import loveShakeIcon from "Assets/images/love-shake.svg";

function BigTitle({ children }) {
  return (
    <section className="section-big-title">
      <div className="big-title-container">
        <div className="big-title-img">
          <img src={`${loveShakeIcon}`} alt="hand shake with love shape" />
        </div>
        <div className="big-title-desc">{children}</div>
      </div>
    </section>
  );
}

export default BigTitle;
