import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import logo from "Assets/images/medical-alert-logo.png";
import emailIcon from "Assets/images/email-icon.svg";
import whatsappIcon from "Assets/images/whatsapp-icon.svg";
import FormGroup from "Molecules/FormGroup";
import { useForm } from "react-hook-form";
import closeIcon from "Assets/images/close-icon.svg";
import { useGlobalState } from "../../../context/GlobalStateProvider";

function Contact() {
  const { isMobile } = useGlobalState();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const formFields = [
    {
      name: "name",
      label: "Name",
      type: "input",
      req: true,
      class: "col-md-6 col-12 mb-3",
      reqmsg: "Name is Required",
      size: "lg",
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      req: true,
      class: "col-md-6 col-12 mb-3",
      reqmsg: "Email is Required",
      size: "lg",
    },
    {
      name: "message",
      label: "Message",
      req: true,
      class: "col-12 mb-12",
      reqmsg: "Message is Required",
      size: "lg",
      as: "textarea",
      rows: isMobile ? 10 : 5,
    },
  ];

  const goBack = () => {
    navigate(-1);
  };

  const onFinish = async (val) => {
    console.log(val);
  };

  return (
    <>
      {/* Pricing */}
      <section className="section-bottom contact">
        {/* Header */}
        <div className="signup-header m-dev">
          <img className="company-logo" src={logo} alt="Medical Alert Logo" />
          <img
            className="close-btn"
            src={closeIcon}
            alt="Close Icon"
            onClick={goBack}
          />
        </div>

        <div className="container">
          <div className="bottom-container">
            <div className="bottom-body">
              <Form onSubmit={handleSubmit(onFinish)}>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 text-center mb-5 d-dev">
                        <img
                          className="company-logo"
                          src={logo}
                          alt="company logo"
                        />
                      </div>
                      <div className="col-12 text-center mb-3">
                        <h2>Contact Us</h2>
                        <p className="primary-color">
                          Monday- Friday 9am - 5pm
                        </p>
                      </div>
                      {/* {formFields?.map((x, index) => {
                        return (
                          <Fragment key={index}>
                            <FormGroup
                              item={x}
                              errors={errors}
                              register={register}
                            />
                          </Fragment>
                        );
                      })} */}
                    </div>
                    {/* <div className="d-dev">
                      <div className="row my-5 justify-content-center send-msg-btn">
                        <div className="col-9">
                          <Button
                            type="submit"
                            className="primary-btn text-center w-100"
                          >
                            Send Message
                          </Button>
                        </div>
                      </div>
                    </div> */}
                    <div className="d-dev mt-5">
                      <div className="row justify-content-center text-center">
                        <div className="col-6 mb-3">
                          <a
                            href="mailto:support@medicalert.org.my"
                            className="d-flex align-items-center justify-content-center"
                            style={{ fontSize: "13px" }}
                          >
                            <img
                              style={{ marginRight: "1rem" }}
                              src={emailIcon}
                              alt="email icon"
                            />
                            <span>support@medicalert.org.my</span>
                          </a>
                        </div>
                        <div className="col-6 mb-3">
                          <a
                            href="https://wa.me/60184710020"
                            className="d-flex align-items-center justify-content-center"
                            style={{ fontSize: "13px" }}
                            target="_blank"
                          >
                            <img
                              style={{ marginRight: "1rem" }}
                              src={whatsappIcon}
                              alt="whatsapp icon"
                            />
                            <div style={{ textAlign: "left" }}>
                              <div>+60184710020</div>
                              <div className="contact-desc">
                                WhatApp Business
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="m-dev">
                      <div className="row text-center primary-color mt-5">
                        <div className="col-12 mb-3">
                          <div className="font-bold mb-3">Contact Us</div>
                          <a
                            style={{ display: "block" }}
                            href="tel:+60184710020"
                          >
                            +60184710020
                          </a>
                          <a
                            style={{ display: "block" }}
                            href="mailto:support@medicalert.org.my"
                          >
                            support@medicalert.org.my
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* <div className="m-dev">
                      <div className="row my-5 justify-content-center send-msg-btn">
                        <div className="col-12">
                          <Button
                            type="submit"
                            className="primary-btn text-center w-100"
                          >
                            Send Message
                          </Button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
