import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { updatePassword } from "../../../ducks/services";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";

export default (props) => {
  const { isMobile } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch: watch,
  } = useForm();

  let checkPassword = watch("password");

  // password setting
  const formFields = [
    {
      name: "password",
      label: "Enter new password",
      type: "password",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "New Password is Required",
      size: "lg",
      registerProps: {
        validate: (value) =>
          value.length >= 6 || "Please enter at least 6 characters",
      },
    },
    {
      name: "confirm_password",
      label: "Confirm new password",
      type: "password",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "New Password is Required",
      size: "lg",
      registerProps: {
        validate: (value) =>
          value === checkPassword || "The Password does not match",
      },
    },
  ];

  const onFinishPassword = async (val) => {
    props.setLoading(true);

    const payload = {
      password: val.password,
    };

    await updatePassword(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success("Password updated successfully!");
          reset2();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <Form onSubmit={handleSubmit(onFinishPassword)}>
      <div className="password-setting">
        <div className="row">
          {formFields?.map((item, index) => {
            return (
              <Fragment key={index}>
                <FormGroup item={item} errors={errors} register={register} />
              </Fragment>
            );
          })}
        </div>
        <div className="flex ju-c-end">
          <Button
            type="submit"
            className={`primary-common-button w-${isMobile ? "100" : "25"}`}
            size="lg"
          >
            Change Password
          </Button>
        </div>
      </div>
    </Form>
  );
};
