import React, { useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";

export default (props) => {
  const override = {
    display: "block",
    margin: "0 auto",
    zIndex: "3",
    position: "fixed",
    left: "45%",
    top: "50%",
  };

  return (
    <div className={`App ${props.loading ? "parentDisabled" : ""}`}>
      <CircleLoader
        loading={props.loading}
        color={"red"}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <div className="signup-page">{props.children}</div>
    </div>
  );
};
