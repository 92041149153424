import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormSelect, Button } from "react-bootstrap";
import { updateProfile } from "../../../ducks/services";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";
import dayjs from 'dayjs';

const countries = [
  "Malaysia",
  "Singapore"
];

const states = [
  "WP Kuala Lumpur",
  "Johor",
  "Kedah",
  "Kelantan",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Penang",
  "Perak",
  "Perlis",
  "Sabah",
  "Sarawak",
  "Selangor",
  "Terengganu",
  "WP Labuan",
  "WP Putrajaya",
  "Singapore"
];

export default (props) => {
  const { user, meta, isMobile } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const formFields = [
    {
      name: "email",
      label: "Email",
      type: "input",
      req: true,
      class: "col-12",
      reqmsg: "Email is Required",
      size: "lg"
    },
    {
      name: "title",
      label: "Salutation",
      type: "select",
      options: meta?.salutations,
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Salutation is Required",
      size: "lg"
    },
    {
      name: "name",
      label: "Full Name",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Full Name is Required",
      size: "lg"
    },
    {
      name: "nric",
      label: "IC Number / Passport",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "IC Number / Passport is Required",
      size: "lg"
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      options: meta?.genders,
      req: true,
      class: "col-md-3 col-12",
      reqmsg: "Gender is Required",
      size: "lg"
    },
    {
      name: "race",
      label: "Ethnic",
      type: "input",
      req: true,
      class: "col-md-3 col-12",
      reqmsg: "Ethnic is Required",
      size: "lg"
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: "date",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Date of Birth is Required",
      size: "lg"
    },
    {
      name: "phone",
      label: "Phone (Mobile)",
      type: "input",
      req: true,
      class: "col-md-3 col-12",
      reqmsg: "Phone (Mobile) is Required",
      size: "lg"
    },
    {
      name: "home_phone",
      label: "Phone (Home)",
      type: "input",
      req: true,
      class: "col-md-3 col-12",
      reqmsg: "Phone (Home) is Required",
      size: "lg"
    },
    {
      name: "address",
      label: "Mailing Address",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Mailing Address is Required",
      size: "lg"
    },
    {
      name: "state",
      label: "State",
      type: "select",
      options: states,
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "State is Required",
      size: "lg"
    },
    {
      name: "postcode",
      label: "Postcode",
      type: "input",
      req: true,
      class: "col-md-3 col-12",
      reqmsg: "Postcode is Required",
      size: "lg"
    },
    {
      name: "country",
      label: "Country",
      type: "select",
      options: countries,
      req: true,
      class: "col-md-3 col-12",
      reqmsg: "Country is Required",
      size: "lg"
    },
    {
      name: "nationality",
      label: "Nationality",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Nationality is Required",
      size: "lg"
    }
  ];

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
      setValue("title", user.title);
      setValue("name", user.name);
      setValue("nric", user.nric);
      setValue("gender", user.gender);
      setValue("race", user.race);
      setValue("dob", user.dob);
      setValue("phone", user.phone);
      setValue("home_phone", user.home_phone);
      setValue("address", user.address);
      setValue("state", user.state ?? "");
      setValue("postcode", user.postcode);
      setValue("country", user.country_en);
      setValue("nationality", user.nationality);
    }
  }, [user]);

  const onFinishProfile = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      dob: dayjs(val?.dob).format("YYYY-MM-DD"),
    };

    await updateProfile(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success("Profile updated successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <Form onSubmit={handleSubmit(onFinishProfile)}>
      <div className="my-profile">
        <div className="row">
          {
            formFields?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <FormGroup item={item} errors={errors} register={register} />
                </Fragment>
              )
            })
          }
        </div>
        <div className="row justify-content-end">
          <div
            className={`col-${isMobile ? "12" : "6"
              } mb-3 align-self-center text-right`}
          >
            <Button
              size="lg"
              disabled={props.loading}
              className={`${isMobile ? "primary-btn text-white" : "secondary-btn"
                }`}
              type="submit"
              style={{
                background: `${isMobile ? "#ed174e" : "unset"}`,
                width: "100%",
                maxWidth: `${isMobile ? "100%" : "268px"}`,
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
};