import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DatePicker from "react-widgets/DatePicker";
import stepImg from "Assets/images/step-img.svg";
import Button from "react-bootstrap/Button";

import topbarLogo from "Assets/images/medical-alert-logo.png";
import closeIcon from "Assets/images/close-icon.svg";

const formFields = [
  {
    name: "first_name",
    label: "First Name",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "First Name is Required",
  },
  {
    name: "last_name",
    label: "Last Name",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Last Name is Required",
  },
  {
    name: "phone",
    label: "Phone",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Phone is Required",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Email is Required",
  },
  {
    name: "address",
    label: "Home Address (House No./Unit No)",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Address is Required",
  },
  {
    name: "state",
    label: "State & Area",
    type: "select",
    req: true,
    options: [],
    class: "col-md-6 col-12",
    reqmsg: "State is Required",
  },
  {
    name: "postcode",
    label: "Postal Code",
    type: "input",
    req: true,
    class: "col-md-3 col-6",
    reqmsg: "Postal Code is Required",
  },
  {
    name: "country",
    label: "Country",
    type: "select",
    req: true,
    options: [],
    class: "col-md-3 col-6",
    reqmsg: "Postal Code is Required",
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Date of Birth is Required",
  },
];

export default (props) => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [dob, setDob] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!dob) {
      setError("Date of Birth is Required");
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const navigateToSignUp = () => {
    navigate("/sign-up");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="signup-page">
      <div className="mobile-menu">
        <div className="mobile-menu-header">
          <Link to="/home">
            <img
              className="company-logo"
              src={topbarLogo}
              alt="Medical Alert Logo"
            />
          </Link>
          <img
            className="close-btn"
            src={closeIcon}
            alt="Close Icon"
            onClick={goBack}
          />
        </div>
        <div className="signup-body">
          <div className="container">
            <div className="row">
              <div
                className="sign-up-body-title text-center"
                style={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                  marginBottom: "2rem",
                }}
              >
                <div>Join Now</div>
                <div className="primary-color">Membership</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-1 mb-5">
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px",
                    border: "2px solid red",
                    borderRadius: "15px",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  The package in your 1 year membership renewal as well as NFC
                  Bracelet & Pendant. Easily scan NFC Bracelet & Pendant via
                  smartphone and retrieve your medical information.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-body">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 text-center bottom-header mb-0">
                          <div className="text-dark mb-0">Join Now</div>
                          <div className="text-primary">Membership</div>
                        </div>
                        {/* <div className="col-12 text-center mb-5">
                          <p className="step-box-body-content">
                            Please note, this may take up to 48 hours to
                            process.
                          </p>
                        </div> */}
                        {formFields?.map((item, index) => {
                          return (
                            <div className={item.class} key={index}>
                              <Form.Group
                                controlId={item.name}
                                className="mb-5"
                              >
                                <Form.Label>
                                  {item.label} {item.req && "*"}
                                </Form.Label>
                                {item.type === "select" ? (
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="form-control"
                                  >
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </Form.Select>
                                ) : item.type === "date" ? (
                                  <DatePicker
                                    selected={dob}
                                    onChange={(date) => setDob(date)}
                                    placeholder="m/dd/yy"
                                  />
                                ) : (
                                  <Form.Control
                                    size="lg"
                                    type={item.type}
                                    required={item.req}
                                  />
                                )}
                                <Form.Control.Feedback type="invalid">
                                  {item.reqmsg}
                                </Form.Control.Feedback>
                                {error && item.type === "date" && (
                                  <Form.Text className="text-danger">
                                    {error}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </div>
                          );
                        })}
                        <div className="col-3 text-center mt-1 mb-5">
                          <img src={stepImg} alt="" className="w-100" />
                        </div>
                        <div className="col-9 mt-1 mb-5">
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "30px",
                              border: "2px solid red",
                              borderRadius: "15px",
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            The package in your 1 year membership renewal as
                            well as NFC Bracelet & Pendant. Easily scan NFC
                            Bracelet & Pendant via smartphone and retrieve your
                            medical information.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <hr />
                        </div>
                      </div>
                      <div className="row my-5 justify-content-center">
                        <div className="col-6">
                          <h3
                            className="text-primary text-end"
                            style={{ lineHeight: "150%" }}
                          >
                            Total Cost: Renewal Membership 1 year
                            <br />
                            =RM200.00
                          </h3>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4">
                          <Button
                            variant="primary"
                            className="w-100 h-100 text-white"
                            style={{ borderRadius: "15px", fontSize: "100%" }}
                            type="submit"
                          >
                            Make Payment
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
