import axios from 'axios';
import { externalBaseUrl } from '../configs/constants';

const authInterceptors = axios.create({
  baseURL: externalBaseUrl,
});

authInterceptors.interceptors.request.use(
  (config) => {
    let token = undefined;
    if (localStorage.getItem("token")) {
      token = JSON.parse(localStorage.getItem('token')).access_token;
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

authInterceptors.interceptors.response.use(
  (response) => {
    // if ([406].includes(response.data.code)) {
    //   window.location.assign('/error');
    //   return Promise.reject(response);
    // } else
    if (response?.data?.meta?.access_token) {
      let existingToken = JSON.parse(localStorage.getItem("token"));
      if (existingToken) {
        existingToken.access_token = response.data.meta.access_token;
        localStorage.setItem('token', JSON.stringify(existingToken));
      }
    }
    return response;
  },
  function (error) {
    if ([401, 1001].includes(error.response.data.code) || error.response.data.message === "Unauthorized") {
      localStorage.clear();
      setTimeout(() => {
        // message.error(error.response.data.message);
        setTimeout(() => {
          window.location.assign('/')
        }, 500);
      }, 1000);
    } else if ([500].includes(error.response.status)) {
      localStorage.clear();
      window.location.assign('/')
    }
    return Promise.reject(error.response);
  },
);
export default authInterceptors;
