import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoginLogo from "Assets/images/medical-alert-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "Services/axiosInterceptor";
import { externalBaseUrl } from "../../../configs/constants";
import toast from "react-hot-toast";

function ResetPassword(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const token = queryParameters.get("token");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  let checkPassword = watch("newPassword");

  const onFinish = (values) => {
    props.setLoading(true);
    const payload = {
      reset_password_token: token,
      new_password: values.newPassword,
    };
    axios
      .post(`${externalBaseUrl}/users/reset-password`, payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success(response?.data?.message);
          navigate("/login");
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e?.message ?? "Something went wrong");
      });
  };

  return (
    <Form className="login-page" onSubmit={handleSubmit(onFinish)}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-5">
          <div className="card login-form form">
            <div className="card-body">
              <div className="row">
                <div className="col-12 text-center mb-5 d-dev">
                  <img src={LoginLogo} alt="Login" className="w-50" />
                </div>
                <div className="col-12 text-center">
                  <h2 className="mb-4">Reset Password</h2>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  <Form.Group controlId="newPassword" className="mb-3">
                    <Form.Label>Enter new password</Form.Label>
                    <Form.Control
                      size="lg"
                      type="password"
                      isInvalid={Boolean(errors.newPassword)}
                      {...register("newPassword", {
                        required: true,
                        validate: (value) =>
                          value.length >= 6 ||
                          "Password needs to be at least 6 characters.",
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.newPassword?.type === "required"
                        ? "New Password is required"
                        : errors?.newPassword?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12 mb-3">
                  <Form.Group controlId="confirmNewPassword" className="mb-3">
                    <Form.Label>Confirm new password</Form.Label>
                    <Form.Control
                      size="lg"
                      type="password"
                      isInvalid={Boolean(errors.confirmNewPassword)}
                      {...register("confirmNewPassword", {
                        required: true,
                        validate: (value) =>
                          value === checkPassword ||
                          "The Password does not match",
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.confirmNewPassword?.type === "required"
                        ? "Confirm New Password is required"
                        : errors?.confirmNewPassword?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row my-5 justify-content-center">
                <div className="col-9 login-btn-wrapper">
                  <Button
                    className="primary-btn text-center w-100"
                    type="submit"
                    disabled={props.loading}
                  >
                    {props.loading ? <Spinner animation="border" /> : "Confirm"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default ResetPassword;
