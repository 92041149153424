import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import * as TabCards from "./tabList";
import { useGlobalState } from "../../../../../context/GlobalStateProvider";

export default (props) => {
  const { reloadApi } = props;
  const userId = localStorage.getItem("userid");
  const user = useSelector((state) => state.dashboard.user);
  const meta = useSelector((state) => state.dashboard.userOption);
  const dispatch = useDispatch();
  // Responsive
  const { isMobile } = useGlobalState();

  const tabs = [
    {
      key: "illness",
      title: "Medical Illness",
      Comp: "Illness",
    },
    {
      key: "allergies",
      title: "Allergies",
      Comp: "Allergy",
    },
    {
      key: "information",
      title: "Medical Information",
      Comp: "Information",
    },
    {
      key: "doctordetail",
      title: "Doctor's Details",
      Comp: "DoctorDetail",
    },
    {
      key: "medical-report",
      title: "Medical Report",
      Comp: "MedicalReport",
    },
  ];

  return (
    <Tabs
      defaultActiveKey="illness"
      id="fill-tab-example"
      className="mb-5 eq-size"
    >
      {tabs.map((item, index) => {
        const Cardi = TabCards[item.Comp];
        return (
          <Tab eventKey={item.key} title={item.title} key={index}>
            <Cardi
              {...props}
              user={user}
              meta={meta}
              isMobile={isMobile}
              reloadApi={reloadApi}
            />
          </Tab>
        );
      })}
    </Tabs>
  );
};
