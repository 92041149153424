import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import postageImg from "Assets/images/resources/postage-img.png";
import returnImg from "Assets/images/resources/return-img.png";
import postageIcon from "Assets/images/resources/postage-icon.svg";
import returnIcon from "Assets/images/resources/return-icon.svg";
import BottomContainer from "../../../components/common/SectionBottom/BottomContainer";
import BottomHeader from "../../../components/common/SectionBottom/BottomHeader";
import BottomBody from "../../../components/common/SectionBottom/BottomBody";
import BottomFooter from "../../../components/common/SectionBottom/BottomFooter";

const headerAccordion = [
  {
    title: "What emergency professional training courses are available?",
    body: `CPR and AED training is available through MyResQ. MyResQ is led by a team of emergency physicians and clinicians committed to reducing the death and disability rates associated with cardiac arrest.
    Our team intends to champion this potentially life-saving cause. We have initiated a novel strategy utilising mobile application technology to alert nearby rescuers in order to expedite emergency response times.`,
  },
];

const postageReturnCard = [
  {
    icon: postageIcon,
    title: "Postage",
    content:
      "Standard postage rate applied to all products. However, an express delivery is available for an additional cost.",
    toggled: true,
    index: 0,
  },
  {
    icon: returnIcon,
    title: "Returns",
    content:
      "As your absolute satisfaction is our priority, we offer a 21 day member satisfaction guarantee on our Stainless-Steel product range. If you require an alteration or replacement, or the product doesn’t meet your expectations, simply return the product and we will replace or alter it free of charge.",
    toggled: false,
    index: 1,
  },
];

export default function Resources(props) {
  return (
    <div className="training-support-page">
      {/* Header */}
      <section className="top-title">
        <div className="primary-color">Training & Support</div>
      </section>

      {/* Header */}
      <section className="section-header">
        <div className="container">
          <div className="header-container resource-header-container">
            {/* <div className="header-left resource-header-left">
              <div className="font-bold">
                Resources For <span className="primary-color">MyResQ</span>
              </div>
              <div className="font-bold primary-color">
                & Health Professionals
              </div>
            </div> */}
            <div className="header-right d-dev">
              <div className="header-right-title">
                <h1 className="font-bold">
                  What emergency professional training courses are available?
                </h1>
              </div>
              <div className="header-right-content">
                First responder training is available upon request. The purpose
                of the course is to educate the communities on the value and use
                of MedicAlert medical identification and emergency services.
                This comprehensive online training covers the following topics:
              </div>

              <br />

              <div className="header-right-title">
                <h2 className="font-bold primary-color">
                  MedicAlert Foundation information and services
                </h2>
              </div>
              <div className="header-right-content">
                <ul>
                  <li>MedicAlert history</li>
                  <li>MedicAlert 24/7 Emergency Service</li>
                  <li>Using MedicAlert IDs</li>
                  <li>What’s in the MedicAlert digital health profile</li>
                  <li>
                    Wandering Support services for those with dementia or autism
                  </li>
                </ul>
              </div>

              <br />

              <div className="header-right-title">
                <h2 className="font-bold primary-color">
                  Emergency response topics
                </h2>
              </div>
              <div className="header-right-content">
                <ul>
                  <li>
                    Recognizing various forms and styles of MedicAlert medical
                    IDs
                  </li>
                  <li>
                    Identifying the information keyed in on a MedicAlert ID
                  </li>
                  <li>
                    Understanding the benefits of early recognition of a
                    MedicAlert medical ID
                  </li>
                  <li>
                    Understanding the value of MedicAlert’s 24/7 emergency
                    response service as it pertains to the individual being
                    treated
                  </li>
                  <li>
                    Understanding the risks and liabilities of an ignored or
                    undetected MedicAlert ID
                  </li>
                  <li>
                    Understanding the role of a MedicAlert ID when used as a
                    valid DNR order or advanced directive notification
                  </li>
                </ul>
              </div>

              <br />
              <div>
                To schedule a free training session, click{" "}
                <a
                  href="https://api.whatsapp.com/send?phone=60184710020&text=Hi%20MedicAlert%20Team%2C%0A%0AI%20would%20like%20to%20enquire%20about%20MedicAlert%20Medical%20ID%20Free%20Training.%20%0A%0ABelow%20are%20my%20company%27s%20information%3A%0A1.%20Co%20Name%3A%0A2.%20Person%20In%20Charge%3A%0A3.%20Mobile%20No%3A%0A4.%20Email%20Add%3A%0A5.%20Number%20of%20staff%20to%20attend%20the%20training%3A%20%0A6.%20Training%20Location%3A"
                  target="_blank"
                  className="text-primary font-bold"
                >
                  Contact Us
                </a>{" "}
                to reserve your spot.
              </div>
            </div>
            <div className="header-right m-dev">
              <Accordion defaultActiveKey="0">
                {headerAccordion?.map((item, index) => {
                  return (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body
                        className="text-secondary"
                        dangerouslySetInnerHTML={{ __html: item.body }}
                      ></Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
