import React from "react";

function BottomContainer({ children }) {
  return (
    <section className="section-bottom">
      <div className="bottom-container">
        <div className="bottom-wrapper">{children}</div>
      </div>
    </section>
  );
}

export default BottomContainer;
