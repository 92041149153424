export const csPrefillTxt =
  "https://api.whatsapp.com/send?phone=60184710020&text=Hi%20MedicAlert%20Team%2C%0A%0AI%20am%20an%20existing%20member%20and%20I%20would%20like%20to%20get%20access%20to%20my%20online%20Profile%20Account.%20Below%20is%20my%20information%20for%20you%20to%20process%20my%20request%3A%0AName%20as%20per%20NRIC%3A%0ANRIC%20Number%3A%0AEmail%20address%3A%0A%0AThank%20you%20and%20hope%20to%20hear%20from%20you%20soon.%0A";

export const pricingPackageList = [
  {
    title: "New Membership",
    price: "RM250",
    package: "new",
  },
  {
    title: "Yearly Renewal",
    price: "RM200",
    package: "renew",
    tips: "(For Existing Members)",
  },
];
