import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loveShakeIcon from "Assets/images/love-shake.svg";
import founderImg from "Assets/images/about/founder-img.png";
import leverTechImg from "Assets/images/about/lever-tech.png";
import alignPartnerImg from "Assets/images/about/align-partner.png";
import collaboratingImg from "Assets/images/about/collaborating.png";
import aboutBanner from "Assets/images/about/about-banner.jpg";

import Banner from "../../../components/common/Banner";
import TextLeftImageRight from "../../../components/common/TextLeftImageRight";
import BigTitle from "../../../components/common/BigTitle";
import BottomContainer from "../../../components/common/SectionBottom/BottomContainer";
import BottomHeader from "../../../components/common/SectionBottom/BottomHeader";
import BottomBody from "../../../components/common/SectionBottom/BottomBody";
import BottomFooter from "../../../components/common/SectionBottom/BottomFooter";
import BottomBox from "../../../components/common/SectionBottom/BottomBox";

import { useGlobalState } from "../../../context/GlobalStateProvider";

const bottomBoxes = [
  {
    title: "Leveraging Technology",
    body: "To ensure that members’ health records are portable, and that critical health data is readily accessible in an emergency.",
    image: leverTechImg,
  },
  {
    title: "Aligning With Partnerships",
    body: "With world renowned patient organisations to expand our reach, and provide programs and services that protect the lives of individuals who live with chronic medical conditions.",
    image: alignPartnerImg,
  },
  {
    title: "Collaborating",
    body: "With those in the emergency medicine space to improve emergency outcomes by providing accurate critical health data.",
    image: collaboratingImg,
  },
];

const About = (props) => {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useGlobalState();

  return (
    <div className="about-page">
      <section className="section-header">
        <div className="container">
          <div className="header-container about-header-container">
            <div className="primary-color m-dev">
              <h1 className="font-bold">Our Story</h1>
            </div>
            <div className="header-left about-header-left">
              <div className="font-bold">How the</div>
              <div className="font-bold primary-color">
                MedicAlert Foundation
              </div>
              <div className="font-bold primary-color">makes a difference</div>
            </div>
            <div className="header-right">
              <div className="header-right-title d-dev">
                <h1 className="font-bold">
                  Our <span className="primary-color">Story</span>
                </h1>
              </div>
              <div
                className={`header-right-content secondary-color ${
                  isMobile ? "text-center" : ""
                }`}
              >
                At the MedicAlert Foundation Malaysia, we offer individuals
                facing health challenges the assurance that they're fully backed
                by Malaysia's top-rated emergency medical information service.
                <div className="d-dev">
                  <br />
                  For over fifty years, we've been at the forefront of
                  delivering life-saving aid to Malaysians. With our NFC tags,
                  critical life-saving details can now reach emergency medical
                  professionals swiftly, ensuring prompt and informed care.
                  <br />
                  <br />
                  Endorsed exclusively by paramedics throughout Malaysia, we
                  stand out as the trusted go-to for crucial medical information
                  during emergencies. When seconds count, count on us to provide
                  the essential support needed to save lives.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Banner */}
      <Banner hasDonateBar={true} webBanner={aboutBanner} />

      <section className="section-founder-story">
        <TextLeftImageRight>
          <div className="tl-content">
            <div className="tl-content-title font-italic">
              “I believe I can save more lives with MedicAlert than I ever can
              with my scalpel.” –{" "}
              <span className="primary-color">
                Dr. Marion Collins, MedicAlert founder
              </span>
            </div>
            <div className="tl-content-body secondary-color d-dev">
              It all began with a father's determination to safeguard his
              daughter from a dangerous allergy, sparking a mission that
              continues to make a difference worldwide. In 1956, Dr. Marion and
              Chrissie Collins established the MedicAlert Foundation,
              introducing America's first widely recognized medical
              identification symbol. Their goal, then and now, is clear: to save
              lives by sharing crucial medical details during emergencies.
              <br />
              <br />
              In 1971, thanks to the collaboration between Rotary and St John
              Council, MedicAlert expanded its reach to Malaysia. It became a
              registered charity and established its national headquarters in
              Adelaide, South Australia. As Malaysia's sole not-for-profit
              organization of its kind, MedicAlert Foundation remains dedicated
              to offering peace of mind to individuals globally facing medical
              conditions. Today, over 65 years later, MedicAlert operates in 11
              countries worldwide.
            </div>
          </div>
          <div className="ir-content">
            <img src={founderImg} alt="Founder" />
          </div>
          <div className="tl-content-body secondary-color m-dev">
            What started as a father’s desire to protect his daughter from a
            fatal allergy became a mission that to this day is changing the
            world. Dr Marion and Chrissie Collins, created MedicAlert Foundation
            in 1956, America’s first universal symbol of medical identification.
            The organisation’s mission, then and today, is simple: to save and
            protect lives by sharing vital medical information in our members’
            moments of need.
            <br />
            <br />
            In 1971, Rotary and St John Council worked together to bring
            MedicAlert to Malaysia, registering as an Malaysian charity and
            setting up national headquarters in Adelaide, South Australia. As
            Malaysia’s only not-for-profit organisation of its kind, today
            MedicAlert Foundation is still committed to providing peace of mind
            to a global community of people living with a medical condition.
            Over 65 years later, MedicAlert operates out of 9 countries around
            the world.
          </div>
        </TextLeftImageRight>
      </section>

      {/* Middle Title */}
      <BigTitle>
        <h1 className="text-capitalize">
          <span className="primary-color">To save and protect lives</span> by
          sharing information in our members’ moment of need.
        </h1>
      </BigTitle>
    </div>
  );
};

export default About;
