import React from "react";
import verifyIcon from "Assets/images/verify.svg";
import loveIcon from "Assets/images/love.svg";
import worldIcon from "Assets/images/world.svg";
import healthcareIcon from "Assets/images/healthcare.svg";

function BannerInfo() {
  return (
    <div className="banner-info-container">
      <div className="banner-info-item">
        <div className="banner-info-img">
          <img src={`${verifyIcon}`} alt="verify" />
        </div>
        <span>Twice Verified Rewards</span>
      </div>
      <div className="banner-info-item">
        <div className="banner-info-img">
          <img src={`${loveIcon}`} alt="report" />
        </div>
        <span>Over 50 Years Of Service</span>
      </div>
      <div className="banner-info-item">
        <div className="banner-info-img">
          <img src={`${worldIcon}`} alt="world" />
        </div>
        <span>Internationally Recognised</span>
      </div>
      <div className="banner-info-item">
        <div className="banner-info-img">
          <img src={`${healthcareIcon}`} alt="healthcare" />
        </div>
        <span>94% Healthcare Confidence</span>
      </div>
    </div>
  );
}

export default BannerInfo;
