import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { updateDoctorDetails } from "../../../../../ducks/services";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";
import Accordion from "react-bootstrap/Accordion";
import { Modal } from "react-bootstrap";

export default (props) => {
  const { user, isMobile, reloadApi } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [doctorDetailsList, setDoctorDetailsList] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [selectedRemove, setSelectedRemove] = useState(null);

  const addDoctor = () => {
    if (doctorDetailsList.length === 10) {
      toast.error("Only maximum number of 10 doctors can be added");
      return;
    }

    const newDoctor = {
      form_fields: [
        {
          name: `id-${doctorDetailsList.length ? doctorDetailsList.length : 0}`,
          label: "id",
          type: "input",
          hidden: true,
        },
        {
          name: `name-${
            doctorDetailsList.length ? doctorDetailsList.length : 0
          }`,
          label: "Doctor's Name",
          type: "input",
          req: true,
          class: "col-md-6 col-12",
          reqmsg: "Doctor's Name is Required",
          size: "lg",
        },
        {
          name: `company-${
            doctorDetailsList.length ? doctorDetailsList.length : 0
          }`,
          label: "Hospital / Clinic Name",
          type: "input",
          req: true,
          class: "col-md-6 col-12",
          reqmsg: "Hospital / Clinic Name is Required",
          size: "lg",
        },
        {
          name: `address1-${
            doctorDetailsList.length ? doctorDetailsList.length : 0
          }`,
          label: "Hospital / Clinic Address",
          type: "input",
          req: true,
          class: "col-md-6 col-12",
          reqmsg: "Hospital / Clinic Address is Required",
          size: "lg",
        },
        {
          name: `phone-${
            doctorDetailsList.length ? doctorDetailsList.length : 0
          }`,
          label: "Contact Number",
          type: "input",
          req: true,
          class: "col-md-6 col-12",
          reqmsg: "Contact Number is Required",
          size: "lg",
        },
        {
          name: `treatment-${
            doctorDetailsList.length ? doctorDetailsList.length : 0
          }`,
          label: "Treatment",
          type: "input",
          req: true,
          class: "col-md-6 col-12",
          reqmsg: "Treatment is Required",
          size: "lg",
        },
      ],
    };

    setFormValue(getValues());

    setDoctorDetailsList((doctorDetailsList) => [
      ...doctorDetailsList,
      newDoctor,
    ]);
  };

  useEffect(() => {
    if (user) {
      if (user?.doctor_details) {
        const detailsData = user?.doctor_details.map((el, i) => ({
          ...el,
          form_fields: [
            {
              name: `id-${i}`,
              label: "id",
              type: "input",
              req: false,
              hidden: true,
            },
            {
              name: `name-${i}`,
              label: "Doctor's Name",
              type: "input",
              req: true,
              class: "col-md-6 col-12",
              reqmsg: "Doctor's Name is Required",
              size: "lg",
            },
            {
              name: `company-${i}`,
              label: "Hospital / Clinic Name",
              type: "input",
              req: true,
              class: "col-md-6 col-12",
              reqmsg: "Hospital / Clinic Name is Required",
              size: "lg",
            },
            {
              name: `address1-${i}`,
              label: "Hospital / Clinic Address",
              type: "input",
              req: true,
              class: "col-md-6 col-12",
              reqmsg: "Hospital / Clinic Address is Required",
              size: "lg",
            },
            {
              name: `phone-${i}`,
              label: "Contact Number",
              type: "input",
              req: true,
              class: "col-md-6 col-12",
              reqmsg: "Contact Number is Required",
              size: "lg",
            },
            {
              name: `treatment-${i}`,
              label: "Treatment",
              type: "input",
              req: true,
              class: "col-md-6 col-12",
              reqmsg: "Treatment is Required",
              size: "lg",
            },
          ],
        }));

        const formData = user?.doctor_details.reduce((acc, item, index) => {
          Object.keys(item).forEach((key) => {
            acc[`${key}-${index}`] = item[key];
          });
          return acc;
        }, {});

        setDoctorDetailsList(detailsData);
        setFormValue(formData);
      }
    }
  }, [user]);

  useEffect(() => {
    doctorDetailsList.forEach((el) => {
      el?.form_fields?.forEach((form_field) => {
        setValue(`${form_field.name}`, formValue?.[form_field.name]);
      });
    });
  }, [doctorDetailsList]);

  const verifyRemove = (val) => {
    setSelectedRemove(val);
    setIsOpenRemoveModal(true);
  };

  const cancelRemove = () => {
    setSelectedRemove(null);
    setIsOpenRemoveModal(false);
  };

  const removeDoctorDetails = () => {
    props.setLoading(true);
    setValue(`id-${selectedRemove}`, "");
    setValue(`name-${selectedRemove}`, "");
    setValue(`address1-${selectedRemove}`, "");
    setValue(`company-${selectedRemove}`, "");
    setValue(`phone-${selectedRemove}`, "");
    setValue(`treatment-${selectedRemove}`, "");
    setDoctorDetailsList((doctorDetail) =>
      doctorDetail.filter((_, index) => index !== selectedRemove)
    );
    setSelectedRemove(null);
    setIsOpenRemoveModal(false);
    props.setLoading(false);
    toast.success("Doctor's Details Removed");
  };

  const onFinishDoctorDetails = async (val) => {
    props.setLoading(true);

    const docDetailsPayload = doctorDetailsList?.map((_, i) => ({
      id: val?.[`id-${i}`] ? val?.[`id-${i}`] : "",
      name: val?.[`name-${i}`],
      company: val?.[`company-${i}`],
      address1: val?.[`address1-${i}`],
      phone: val?.[`phone-${i}`],
      treatment: val?.[`treatment-${i}`],
    }));

    const payload = {
      doctor_details: JSON.stringify(docDetailsPayload),
    };

    await updateDoctorDetails(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success("Doctor's Details Updated Successfully!");
          reloadApi();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <>
      <div className="doctor-details-page">
        {/* Loop doctor details */}
        <Form
          id="doctor-detail-form"
          onSubmit={handleSubmit(onFinishDoctorDetails)}
        >
          {doctorDetailsList && doctorDetailsList?.length ? (
            <Accordion
              className={`doctor-details-conatainer`}
              defaultActiveKey="container"
            >
              <Accordion.Item eventKey="container">
                <Accordion.Header>Doctors' Details</Accordion.Header>
                <Accordion.Body>
                  <Accordion
                    className={`doctor-details-child`}
                    defaultActiveKey="doctor-details-0"
                  >
                    {doctorDetailsList.map((el, i) => (
                      <Accordion.Item
                        eventKey={`doctor-details-${i}`}
                        key={`doctor-details-${i}`}
                      >
                        <Accordion.Header>
                          {el.name
                            ? el.name
                            : `New doctor's details - ${i + 1}`}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="next-of-kin">
                            <div className="row mb-5">
                              {el.form_fields?.map((item, index) => {
                                return (
                                  <Fragment key={index}>
                                    <FormGroup
                                      item={item}
                                      errors={errors}
                                      register={register}
                                    />
                                  </Fragment>
                                );
                              })}
                            </div>
                            <div className="flex j-c-end">
                              <div
                                className="login-button cursor-pointer"
                                onClick={() => verifyRemove(i)}
                              >
                                Remove Doctor's Details
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <div
              style={{
                minHeight: "33vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Doctor's Details Found. Please click + button to add Doctor's
              Details.
            </div>
          )}
        </Form>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: `${isMobile ? "column" : "row"}`,
            gap: "10px",
          }}
        >
          <Button
            disabled={props.loading}
            className={`${
              isMobile ? "primary-btn text-white" : "secondary-btn"
            }`}
            style={{
              background: `${isMobile ? "#ed174e" : "unset"}`,
              padding: "10px 20px",
              borderRadius: `${isMobile ? "auto" : "50%"}`,
              fontSize: "16px",
              width: `${isMobile ? "100%" : "unset"}`,
            }}
            onClick={addDoctor}
          >
            +
          </Button>
          <Button
            size="lg"
            disabled={props.loading}
            className={`${
              isMobile ? "primary-btn text-white" : "secondary-btn"
            }`}
            type="submit"
            style={{
              background: `${isMobile ? "#ed174e" : "unset"}`,
              padding: "10px 30px",
              fontSize: "16px",
              width: `${isMobile ? "100%" : "unset"}`,
            }}
            form="doctor-detail-form"
          >
            Update
          </Button>
        </div>
      </div>

      <Modal
        className="removeModal"
        show={isOpenRemoveModal}
        onHide={() => setIsOpenRemoveModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <h3 className="text-center m-b-30">
            Are you sure to remove the Doctor's Details?
          </h3>
          <div className="flex j-c-center flex-gap-20">
            <div
              className="secondary-common-button cursor-pointer"
              onClick={cancelRemove}
            >
              Cancel
            </div>
            <div
              className="primary-common-button cursor-pointer"
              onClick={removeDoctorDetails}
            >
              Remove
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
