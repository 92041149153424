import React from "react";
import { Modal } from "react-bootstrap";

function AfterSignUpModal({ openModal, handleCloseModal }) {
  return (
    <Modal show={openModal} onHide={handleCloseModal} centered size="lg">
      <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
      <Modal.Body style={{ padding: "30px" }}>
        Thank you for your generous support of the MedicAlert Foundation! Your
        donation helps us save lives and provide essential medical ID services.
        <br />
        <br />
        Once your donation is verified, we’ll email you your login ID and
        password for your MedicAlert Personal Account. This will allow you to
        update your medical records easily.
      </Modal.Body>
    </Modal>
  );
}

export default AfterSignUpModal;
