import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authentications } from "../../../services/services";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../features/userSlice";
import LoginLogo from "Assets/images/medical-alert-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onFinish = (values) => {
    props.setLoading(true);
    localStorage.clear();
    authentications(values.username, values.password)
      .then((response) => {
        performLogin(response);
      })
      .catch((error) => {
        props.setLoading(false);
        toast.error(error?.data?.message);
      });
  };

  const performLogin = (response) => {
    let res = {
      access_token: response.data.data.access_token,
      expires_in: response.data.data.expires_in,
      refresh_token: response.data.data.refresh_token,
    };

    if (res) {
      let data = response.data.data;
      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("userid", JSON.stringify(data.id));
      if (data.username) localStorage.setItem('userName', data.username);
      if (data.email) localStorage.setItem('email', data.email);
      if (data.photo_url) localStorage.setItem("userImage", data.photo_url);
      localStorage.setItem("token", JSON.stringify(res));
      dispatch(
        login({
          username: data.email || data.username,
        })
      );
      setTimeout(() => {
        props.setLoading(false);
        navigate("/account");
      }, 1000);
    }
  };

  return (
    <Form className="login-page" onSubmit={handleSubmit(onFinish)}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-5">
          <div className="card login-form form">
            <div className="card-body">
              <div className="row">
                <div className="col-12 text-center mb-5 d-dev">
                  <img src={LoginLogo} alt="Login" className="w-50" />
                </div>
                <div className="col-12 text-center">
                  <h2 className="mb-4">Member Login</h2>
                  <p className="text-secondary">
                    If you have an existing MedicAlert username, log in here
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  <Form.Group controlId="username" className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      isInvalid={Boolean(errors.username)}
                      {...register("username", {
                        required: true,
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.username?.type === "required" &&
                        "Email is required"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12 mb-3">
                  <Form.Group controlId="password" className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        size="lg"
                        type={showPassword ? "text" : "password"}
                        isInvalid={Boolean(errors.password)}
                        {...register("password", {
                          required: true,
                          minLength: 6,
                        })}
                      />
                      <InputGroup.Text id="pw-eye" onClick={handleShowPassword}>
                        {!showPassword && (
                          <EyeSlashFill color="#959595" size={26} />
                        )}
                        {showPassword && <EyeFill color="#959595" size={26} />}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {errors?.password?.type === "required" &&
                          "Password is required"}
                        {errors?.password?.type === "minLength" &&
                          "Password should be at-least 6 characters."}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-12 text-center text-secondary">
                  <Link className="text-secondary" to="/forgot-password">
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="row my-5 justify-content-center">
                <div className="col-9 login-btn-wrapper">
                  <Button
                    className="primary-btn text-center w-100"
                    type="submit"
                    disabled={props.loading}
                  >
                    {props.loading ? <Spinner animation="border" /> : "Login"}
                  </Button>
                </div>
                <div className="col-12 text-center mt-5 text-secondary create-account-wrapper">
                  If you don’t have one, click{" "}
                  <Link className="font-bold primary-color" to="/membership">
                    Create Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default Login;
