import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const signUpUser = (payload) => {
  return axios.post(`${externalBaseUrl}/payments/sign-up`, payload);
};

export const signupUserWithReceipt = (payload) => {
  return axios.post(`${externalBaseUrl}/users/signup-user`, payload);
};
