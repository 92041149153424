import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  return (
    <div class="row">
      <div class="col-12">
        <h1>404</h1>
      </div>
      <div class="col-12">
        <h1>Page Not found</h1>
      </div>
      <Link to="/home">Go to Home</Link>
    </div>
  );
};
