import React from "react";

export default (props) => {
  return (
    <div className="policy">
      <div className="row">
        <div className="col-12">
          <span className="jss28">
            {/* <pre style={{ whiteSpace: "pre-line" }}> */}
            Respecting Your Privacy
            <br />
            <br />
            The MedicAlert engraved medical identification product (“Product”),
            MedicAlert website(s) (collectively, “Website”), and services
            provided by MedicAlert, including subscription services
            (collectively, “Services”) (collectively, the Website and Services
            are referred to as the “Platform”) are provided by MedicAlert
            Foundation International, a 501 (c) (3) nonprofit organization
            (“MedicAlert”, “us”, “we”). We recognize that you, as a user of the
            Product and/or Platform, a donor, a member of our mailing lists, or
            other supporter of MedicAlert (“you”) may be concerned about the
            information you provide to us, online or otherwise, and how we treat
            that information. This Privacy Policy addresses those concerns.
            <br />
            <br />
            UNLESS OTHERWISE DEFINED IN THIS PRIVACY POLICY, TERMS USED IN THIS
            PRIVACY POLICY HAVE THE SAME MEANINGS AS IN THE MEDICALERT TERMS AND
            CONDITIONS.
            <br />
            <br />
            Donors
            <br />
            <br />
            All information concerning donors or prospective donors, including
            their names, addresses and telephone numbers, the names of their
            beneficiaries, and the amounts of their gifts, shall be kept
            strictly confidential by MedicAlert, its staff and volunteers,
            unless permission is obtained from donors to release such
            information. MedicAlert will not sell, rent or trade our donors’
            names, postal addresses, email addresses or phone numbers with any
            other entity.
            <br />
            <br />
            Overview of MedicAlert Product and Related Services
            <br />
            <br />
            As further described in the MedicAlert Terms and Conditions, a
            principal purpose of the MedicAlert Product and related Services is
            to provide information about users of the Product and Services to
            MyResQ, health care personnel, and related others in the event of an
            emergency. By purchasing the Product or receiving related Services,
            including paid subscription services, from MedicAlert, and/or any
            partners or third parties that are authorized by MedicAlert to sell
            MedicAlert Products and Services, you authorize MedicAlert to
            release medical and other confidential information about you during
            emergencies to MyResQ, health care personnel, and others, which may
            or may not be designated by you.
            <br />
            <br />
            The Product includes MedicAlert’s toll-free number where
            MedicAlert’s emergency response team is available 24/7 to provide
            information to such MyResQ, health care personnel, and related
            others. To help get the aid you need in an emergency situation, you
            may also call 911 through your phone, or from any phone number you
            have shared with MedicAlert, and when the phone number that
            contacted 911 matches any of the phone numbers you have shared with
            MedicAlert, where available, or when someone provides the MedicAlert
            identification number to the 911 dispatcher, MedicAlert will share
            your information with the 911 dispatcher. The information we share
            with the 911 dispatcher is your name and the gender that you have
            provided to MedicAlert, as well as a calculated age based on the
            date of birth you have provided to MedicAlert. When sharing your
            information with the 911 dispatcher, we will also pass along your
            information to RapidSOS, which provides a technology platform that
            enables data sharing from MedicAlert to 911 dispatchers. RapidSOS’s
            emergency platform will only transmit the MedicAlert data with 911
            dispatchers in the United States. In order to protect you,
            MedicAlert and RapidSOS may retain information about the times when
            the 911 dispatcher received your information. Any summary of such
            911 access information that MedicAlert or RapidSOS keeps will be
            de-identified so that it does not contain any information that can
            be linked to you. MedicAlert and RapidSOS will never record or
            listen to your or any 911 calls or make a transcript of such calls.
            However, all calls, emails, and other communications between you and
            MedicAlert may be recorded.
            <br />
            <br />
            Information Collected by the MedicAlert Platform
            <br />
            <br />
            MedicAlert principally gathers personally identifiable information,
            such as names, addresses, zip/postal codes, and email addresses,
            when voluntarily submitted by you. For example, personally
            identifiable information may be collected to process membership
            applications, profile updates, and Product orders; to respond to a
            stated desire to apply for a position; to volunteer time to
            MedicAlert; to donate online; or to sign up to be part of a
            MedicAlert mailing list. This information is available to MedicAlert
            and other service providers involved in the operation of the Product
            and Platform. MedicAlert requires that all third-party service
            providers hold personal information in strict confidence. While we
            do not own or control these companies, we believe they are
            upstanding and will treat your personal information responsibly.
            Additionally, as with most other Internet sites, our Website
            automatically logs headers your browser sends us and connection
            information, such as IP address, user-agent string, referring URL,
            and dates/times of access. We use the information we record to help
            diagnose problems with our server, and to improve the content of our
            website.
            <br />
            <br />
            Opt-out Policy
            <br />
            <br />
            If you supply us with your postal address, email address or phone
            number, you may receive periodic communications from us with
            information on our programs and services. If you do not want to
            receive postal mail, email or phone calls from us in the future,
            please let us know by sending your request via email to
            opt-out@medicalert.org. Choosing to exercise this anonymous
            marketing opt-out will not affect any of your preferences or the
            choices provided to you as a registered user of MedicAlert. Please
            call MedicAlert at 1.800.432.5378 between the hours of 8:00 am and
            4:30 pm PDT Monday through Friday to change your preferences or
            choices regarding its use of your information.
            <br />
            <br />
            Please note that you cannot unsubscribe from transactional
            communications. This includes email or direct mail pertaining to
            product orders, transaction receipts, invoices, payments, or other
            issues regarding your MedicAlert membership.
            <br />
            <br />
            Exceptions to Opt-out Policy
            <br />
            <br />
            MedicAlert may disclose personal information if compelled by law or
            a regulatory authority. In addition, we may need to disclose
            personal information in special cases when we have reason to believe
            that disclosing this information is necessary to identify, contact
            or bring legal action against someone who may be causing injury to
            or interfering (either intentionally or unintentionally) with
            MedicAlert’s rights or property, MedicAlert’s members, supporters,
            or anyone else that could be harmed by such activities.
            <br />
            <br />
            Protecting Credit Card Information
            <br />
            <br />
            Protecting your credit card information is of the utmost importance
            to MedicAlert. MedicAlert’s site has security measures in place to
            protect the loss, misuse and alteration of information under our
            control. We make every effort to ensure the secure collection and
            transmission of sensitive user information, including credit card
            information, by using encryption technology. This method reflects
            industry standard security protocol and makes it extremely difficult
            for anyone to intercept your credit card information.
            <br />
            <br />
            Credit card numbers are not stored on any MedicAlert servers. In
            MedicAlert’s software applications, credit card numbers are replaced
            with reference tokens that are used to process future transactions
            (e.g., for recurring gift pledges). MedicAlert staff cannot access
            your credit card information at any time.
            <br />
            <br />
            How We Use “Cookies”
            <br />
            <br />
            You should be aware that non-personal information and data may be
            automatically collected by MedicAlert’s Platform through the use of
            “cookies.” Cookies are small text files that a website or online
            service may use to recognize repeat users, facilitate users’ ongoing
            access to and use of the site or service, and allow a site or
            service to track usage behavior and compile aggregate data that will
            facilitate content improvements. If a user does not want information
            collected through the use of cookies, there is a simple procedure in
            most browsers that allows the user to deny or accept the cookie
            feature. For more information about how to disable cookies, refer to
            the “help” or “customer support” section of your specific browser
            application. MedicAlert uses cookie technology to improve users’
            online experience, track, understand, facilitate and personalize
            their use of the Platform, and for research and marketing purposes.
            <br />
            <br />
            How We Use Information
            <br />
            <br />
            We use information we collect for a variety of business purposes,
            including: to provide and improve MedicAlert’s products and
            services; for administrative purposes such as the maintenance and
            operation of the Product and/or Platform or to respond to requests
            that you make; to provide you with a personalized experience on and
            off the Platform, including advertising and promotions; and create
            and utilize metrics, analytics and data related to MedicAlert. We
            also use information to comply with legal obligations, resolve
            disputes, prevent fraud (including by preventing access to
            promotions if you have violated our Terms and Conditions), and
            enforce agreements and the terms of our Services, including our
            Terms and Conditions.
            <br />
            <br />
            We may combine data we collect from different sources, including
            information obtained from third parties, across the Platform and
            devices. We may use information collected to provide you with a
            personalized experience on the Platform, such as providing you with
            coupons or other offers based on your interests and using
            information from your past usage of the Platform in order to present
            or send you notifications of new offers and/or services that may be
            relevant to you.
            <br />
            <br />
            Your IP address may be used to identify or customize promotions for
            you by geographic location. In addition, we may also use information
            to help our clients, partners and vendors improve their services,
            communicate with you about promotions that may be of interest to
            you, prevent fraud and for other promotional and business purposes
            in accordance with this Privacy Policy.
            <br />
            <br />
            As we continue to develop our business, we may buy, sell, or share
            assets in connection with, for example, a merger, acquisition,
            reorganization, sale of assets, or bankruptcy. In such transactions,
            information about users of our Platform is likely to be a
            transferred business asset. In the event that MedicAlert itself or
            substantially all of MedicAlert’s assets are acquired, information
            about users of the Product and Platform may be one of the
            transferred assets.
            <br />
            <br />
            Personal Information
            <br />
            <br />
            The General Data Protection Regulation (‘GDPR’) is a European Union
            regulation concerning the use and processing of personal
            information. GDPR is effective as of May 25, 2018.
            <br />
            <br />
            The MedicAlert Platform is made available from the United States,
            provides services only to residents of the United States, and is
            subject to laws of the United States. If you reside in a country of
            the European Union, please seek information and services from our
            U.K. affiliate at www.medicalert.org.uk. If you are in a country
            outside the United States or Europe and voluntarily submit personal
            information to us, you thereby consent to the general use of such
            information as provided in this Privacy Policy and to the transfer
            of that information to, and/or storage of that information in, the
            United States. To the maximum extent permitted by applicable law,
            MedicAlert disclaims all liability under all circumstances for
            damages resulting from use of information collected from users of
            the Platform. We note that you are under no obligation to disclose
            any personal information to MedicAlert if you do not want to.
            <br />
            <br />
            If you choose to provide personal information to MedicAlert, we will
            hold your information and use it as described in this Privacy
            Policy. We consider that MedicAlert has a legitimate interest in
            collecting and processing your information. MedicAlert’s mission is
            to save and protect lives by sharing information during our members’
            moments of need. This provides our lawful basis to use your personal
            information, within the limitations set forth herein.
            <br />
            <br />
            Links to Third-party Sites
            <br />
            <br />
            To provide certain services and materials, the Platform may provide
            links to allow you to “click” over to other websites and online
            services. This Privacy Policy only applies to the MedicAlert Product
            and Platform. This Privacy Policy does not apply to any third-party
            sites or services. MedicAlert is not responsible for the privacy
            practices or content of such other websites and services, even if
            our name and logo appear on the screen. We recommend that you review
            the privacy policies on third-party sites and services before you
            use them.
            <br />
            <br />
            Information Collected by Third Parties
            <br />
            <br />
            Information, including personal information, may be collected by our
            vendors on our behalf in the operation of the Product and Platform.
            This information may include the location, business name, date,
            and/or time the promotion was redeemed, in addition to other online
            and offline transaction details. We also allow third-party companies
            to use cookies, pixels, or other similar technologies on our
            Platform to collect and use non-personally identifiable information,
            including cookie identifiers, hashed information, and browser and
            clickstream data. These third parties include service providers with
            whom we partner. These third parties may use technologies to provide
            you with promotions, track offline transactions and to assist us in
            providing and improving the Product and Platform. These third
            parties may combine the data they collect from these technologies
            with other data they collect.
            <br />
            <br />
            We may use proprietary and third-party tracking analytics and tools
            to better understand how the Product and Platform are being used and
            by whom. One service provider, Google Analytics, places persistent
            cookies on your computer to analyze your behavior on your visit to
            the Website as well as on other websites, enabling us to improve
            your experience on the Platform. To opt out of the Google Analytics
            collection of information, use this link:
            https://tools.google.com/dlpage/gaoptout.
            <br />
            <br />
            California Privacy Disclosure
            <br />
            <br />
            MedicAlert may collect information about users over time and across
            different websites when you use the Platform. There may also be
            third parties that collect information this way. The Platform does
            not currently respond to “Do Not Track” browser signals or
            mechanisms. However, other settings within your browser may permit
            you to block cookies or use other technologies that enable websites
            to collect information that can be used to tailor advertisements to
            your interest.
            <br />
            <br />
            If you are a California resident, under California’s “Shine the
            Light” law you may have the right to request and receive certain
            information about our disclosure of your personal information to
            third parties for their own marketing use, and your choices with
            respect to such disclosures once per year. If applicable, this
            information would include the types of customer information and the
            businesses with which we shared your customer information for the
            prior calendar year. If you are a California resident and would like
            to request a copy of this information, please submit a request to us
            at the address provided in the Contact Us section below. In your
            request, please specify that you want a “Your California Privacy
            Rights Notice.” Please allow 30 days for a response.
            <br />
            <br />
            Children’s Privacy
            <br />
            <br />
            MedicAlert believes every measure of precaution should be taken to
            protect children online and does not knowingly ask children ages 13
            and under for any information. Users of the Platform who are 13 and
            under should ask their parent or legal guardian for assistance when
            using the Platform and should not submit any personally identifiable
            information to MedicAlert.
            <br />
            <br />
            Changes to Privacy Policy
            <br />
            <br />
            MedicAlert may revise this Privacy Policy from time to time without
            advance notice. Accordingly, we recommend that you check for updates
            to this Privacy Policy on an ongoing basis. Changes were last made
            to this Privacy Policy on April 14, 2021.
            <br />
            <br />
            Contact Us
            <br />
            <br />
            If you have any questions about this Privacy Policy, please contact:
            <br />
            MedicAlert Foundation
            <br />
            101 Lander Ave.
            <br />
            Turlock, CA 95380
            <br />
            Attn: Privacy Officer
            <br />
            Email: privacy@medicalert.org
            <br />
            <br />
            By using MedicAlert’s Product and Platform, you signify your
            agreement to this Privacy Policy. Further, your continued use of our
            website following the posting of changes to this Privacy Policy will
            mean you accept those changes.
            <br />
            <br />
            However, you are under no obligation to disclose any personal
            information to MedicAlert if you do not want to. If you are not
            comfortable with MedicAlert’s uses of your personal information, you
            may always choose to opt out or decide not to use those features of
            the Platform that require your personal information.
            {/* </pre> */}
          </span>
        </div>
      </div>
    </div>
  );
};
