import React from 'react';

export default (props) => {
    return (
        <div className="row" style={{minHeight: "50vh"}}>
            <div className="col-6 m-auto">
                <h1>Access Denied</h1>
                <p>
                    You don't have access to this page
                </p>
            </div>
        </div>
    )
}