import React, { useState } from "react";
import HomeLayout from "../HomeLayout";

import { Link, useNavigate } from "react-router-dom";
import topbarLogo from "Assets/images/medical-alert-logo.png";
import closeIcon from "Assets/images/close-icon.svg";

export default (props) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <HomeLayout loading={props.loading}>
      <div className="login container py-5">
        {/* Header */}
        <div className="signup-header m-dev login-nav">
          <img
            className="company-logo"
            src={topbarLogo}
            alt="Medical Alert Logo"
          />
          <img
            className="close-btn"
            src={closeIcon}
            alt="Close Icon"
            onClick={goBack}
          />
        </div>
        <div className="card layout" style={{ minHeight: "50vh" }}>
          <div className="card-body">{props.children}</div>
        </div>
      </div>
    </HomeLayout>
  );
};
