import { intersection } from 'lodash';

export function isLoggedIn() {
	return !! localStorage.getItem('token');
}

export function isArrayWithLength(arr) {
 return (Array.isArray(arr) && arr.length)
}

function conversion() {
    if (!localStorage.getItem('access')){
        return [];
    }
    const roles = JSON.parse(localStorage.getItem('access'));
    let temp = [];
    roles.forEach((item) => {
        Object.entries(item).forEach(([k, v]) => {
            temp.push(v)
        })
    })
    return temp;
}

function conversion2() {
    if (!localStorage.getItem('access')) {
        return [];
    }
    const roles = JSON.parse(localStorage.getItem('access'));
    let temp = [];
    roles.forEach((item) => {
        Object.entries(item).forEach(([k, v]) => {
            Object.entries(v).forEach(([KEY, VAL]) => {
                if(KEY !== "" && (VAL['read'] == 1 || VAL['write'] == 1 || VAL['delete'] == 1)){
                    temp.push(KEY)
                }
            })
        })
    })
    return temp;
}

export function checkPermission(permission) {
    let permit =  conversion2();
    if(!isArrayWithLength(permission)) return true;
    else return intersection(permission, permit).length;
}

export function allowedRoutes(routes) {
    let permit = conversion2();
    return routes.filter(({ permission }) => {
        if(!permission) return true;
        else if(!isArrayWithLength(permission)) return true;
        else return intersection(permission, permit).length;
    });
}

export function allowed(permission, str = '') {
    let permit2 =  conversion();
    if(!isArrayWithLength(permission)) {
        return true;
    } else {
        let bol = false;
        permit2.map(x => {
            Object.entries(x).forEach(([key, val]) => {
                permission.map(y => {
                    if (key === y && val[str] === 1) {
                        bol = true;
                    }
                })
                
            })
        })
        
        return bol;
    }
    
}

export function allowedCheck() {
    let permit2 =  conversion();
    let bol = true;
    permit2.map(x => {
        Object.entries(x).forEach(([key, val]) => {
            if (key != 'audit' && key != 'internal_office') {
                bol = false;
            }
        })
    })        
    return bol;
    
}

export function allowedAudit() {
    let permit2 =  conversion();
    let bol = true;
    permit2.map(x => {
        Object.entries(x).forEach(([key, val]) => {
            if (key != 'audit') {
                bol = false;
            }
        })
    })        
    return bol;
    
}

export function allowedFeed() {
    let permit2 =  conversion();
    let bol = true;
    permit2.map(x => {
        Object.entries(x).forEach(([key, val]) => {
            if (key != 'internal_office' && key != 'audit') {
                bol = false;
            }
        })
    })        
    return bol;
    
}