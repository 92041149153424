import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../../routing/config/utils";
import { logout } from "Features/userSlice";
import { onClear } from "Modules/Home/ducks/actions";
import { useDispatch } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";

// Components
import Topbar from "../../components/layout/Topbar";
import Footer from "../../components/layout/Footer";
import MobileMenu from "../../components/layout/MobileMenu";

export default function HomeLayout(props) {
  const [isOpenMobMenu, setIsOpenMobMenu] = useState(false);

  // Event
  const handleOpenMobMenu = () => {
    setIsOpenMobMenu(true);
  };

  const handleCloseMobMenu = (e) => {
    if (e.target.closest(".linkToPage") || e.target.closest(".close-btn")) {
      setIsOpenMobMenu(false);
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const override = {
    display: "block",
    margin: "0 auto",
    zIndex: "3",
    position: "fixed",
    left: "45%",
    top: "50%",
  };

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(onClear());
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className={`App ${props.loading ? "parentDisabled" : ""}`}>
      <CircleLoader
        loading={props.loading}
        color={"red"}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <Topbar
        onOpenMobMenu={handleOpenMobMenu}
        isLoggedIn={isLoggedIn}
        logoutHandler={logoutHandler}
      />

      <main>{props.children}</main>

      <Footer />

      {/* Mob Menu */}
      {isOpenMobMenu && (
        <MobileMenu
          onCloseMobMenu={handleCloseMobMenu}
          isLoggedIn={isLoggedIn}
          logoutHandler={logoutHandler}
        />
      )}
    </div>
  );
}
