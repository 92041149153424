import React, { useEffect, useState } from "react";
import SignupLayout from "../../templates/SignUpLayout";
import SignUp from "Modules/SignUp";
import { GlobalStateProvider } from "../../context/GlobalStateProvider";

export default (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <GlobalStateProvider>
      <SignupLayout loading={loading}>
        <SignUp setLoading={setLoading} loading={loading} />
      </SignupLayout>
    </GlobalStateProvider>
  );
};
