import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";

// Images
import amaLogo from "../../../../assets/images/endorsers/AMA.png";
import acepLogo from "../../../../assets/images/endorsers/ACEP.png";
import ahaLogo from "../../../../assets/images/endorsers/AHA.gif";
import kkmLogo from "../../../../assets/images/endorsers/KKM.png";
import ppumLogo from "../../../../assets/images/endorsers/PPUM.png";

const headerAccordion = [
  {
    title: "How MedicAlert Benefits Health Professionals",
    body: `<div className="header-right-content">
  <div>
    MedicAlert is endorsed by these national medical associations:
  </div>
  <ul></ul>
  <li>American Hospital Association</li>
  <li>American Medical Association</li>
  <li>American College of Emergency Physicians</li>
  <br />
  <div>
    MedicAlert relies on health professionals, and we work closely
    together to provide the most informed treatment and care, and
    to avoid potential medical errors. A complete view into an
    individual’s full medical history can help clinicians, as well
    as emergency personnel, to provide better care and improved
    outcomes.
  </div>
  <div>
    So, while we’re widely recognized for our role in accidents
    and emergencies, MedicAlert can also communicate the complete
    medical history of an individual during doctor’s appointments,
    radiological appointments, and procedures, and to other
    healthcare professionals. There is a great deal of information
    stored in a MedicAlert digital health record to assist health
    professionals in achieving the best possible treatment
    results, including current and past medical conditions,
    medications, implanted devices, allergies, vaccinations and
    much more.
  </div>
</div>`,
  },
];

export default function Resources(props) {
  return (
    <div className="training-support-page">
      {/* Header */}
      <section className="top-title">
        <div className="primary-color">Our Endorsers</div>
      </section>

      {/* Header */}
      <section className="section-header">
        <div className="container">
          <div
            className="header-container resource-header-container"
            style={{ paddingTop: "0" }}
          >
            <div className="header-right text-center">
              <h1 className="font-bold primary-color m-b-2">
                MedicAlert is endorsed by these national medical associations:
              </h1>
              <div className="endorsers-list">
                <div className="flex-top-row flex-row">
                  <img
                    className="top-row-img"
                    src={kkmLogo}
                    alt="Kementerian Kesihatan Malaysia"
                  />
                  <img
                    className="top-row-img"
                    src={ppumLogo}
                    alt="Pusat Perubatan Universiti Malaya"
                  />
                </div>
                <div className="flex-btm-row flex-row">
                  <img
                    className="btm-row-img"
                    src={ahaLogo}
                    alt="American Hospital Association"
                  />
                  <img
                    className="btm-row-img"
                    src={acepLogo}
                    alt="American College of Emergency Physicians"
                  />
                  <img
                    className="btm-row-img"
                    src={amaLogo}
                    alt="American Medical Association"
                  />
                </div>
              </div>
            </div>
            {/* <div className="header-right m-dev">
              <Accordion defaultActiveKey="0">
                {headerAccordion?.map((item, index) => {
                  return (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body
                        className="text-secondary"
                        dangerouslySetInnerHTML={{ __html: item.body }}
                      ></Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
