import React from "react";
import Accordion from "react-bootstrap/Accordion";

const accordionList = [
  {
    title:
      "About Emergency Medical Information and Identification Service Organisations",
    body: `MedicAlert Foundation’s Code of Conduct embodies the values and standards we uphold globally. This code serves as a guide for consumers considering the purchase of a medical ID or emergency medical information service and also guides organizations offering these products and services.\n
      Worldwide, thousands of entities provide emergency medical information and identification services, including wallet cards, pre-engraved jewelry, personal push-button alarms, and personal health records (PHRs). Among these, the MedicAlert Foundation stands out as the only nonprofit medical ID service organization that employs highly skilled medical response personnel to securely communicate your personal health information to MyResQ and hospitals during emergencies.\n
      Notably, Malaysia is the first country in the world to revolutionize Medical ID by integrating it into an NFC chip with cloud-based data storage, making it accessible globally. This advanced Medical ID can store a comprehensive range of medical records, including allergies, medical conditions, medications, emergency contacts, medical history, blood type, vaccination records, insurance agent's contact details, and will & trust agent's contact details.\n
      This innovative approach ensures that vital medical information is readily available to healthcare providers around the world, enhancing the safety and care of individuals in emergencies.
      `,
  },
  {
    title: "How do I know what to look for in an ID?",
    body: "Choosing the right medical ID or emergency information service has become increasingly difficult with so many more options available. Some of the products and services in the marketplace are designed to promote the public’s best interest, but many fall short. Furthermore, medical ID businesses are not subject to the same public or private oversight that other health or medical organisations are. The lack of definitive minimum standards ultimately puts you at risk of not being assisted as expected in the event of a medical emergency. In the absence of such standards, this Code of Conduct is designed to help you to decide which medical ID and which medical ID provider is best for you. Please consider the following five- point checklist to ensure you are making an informed choice of medical ID and / or emergency information services.This checklist, coupled with the nature of your specific condition and needs, will provide an understanding of how to safely determine what level of service would be best suited for you.",
  },
  {
    title: "1. Protections for your personal health information",
    type: "list",
    body: "Organisations offering services to store your personal or medical information – often called a personal health record or PHR – should have policies and procedures in place to maintain the confidentiality and security of your confidential information. The policies should be clear and understandable and should include provisions for:",
    list: [
      "Safeguarding the confidentiality of your personal and medical information as well as information technology practices and disaster recovery systems that meet or exceed applicable laws and regulations.",
      "Informed consent so that you know exactly how and under what circumstances your confidential information will be disclosed.",
      "Equally strong protection of your emergency contact and financial information.",
      "An ongoing compliance function to ensure that the organisation’s privacy and confidentiality policies are enforced and up-to-date. This compliance function should be adequately funded, staffed, trained, and given direct and open access to the organisation’s leadership.",
      "Written notification to you in the event of an unauthorised release or disclosure of your confidential information.",
      "Protecting privacy and security of your personal information at every stage of development of products and services.",
      "Providing you reasonable access to your stored personal data",
      "A records audit process that tracks or provides an “audit trail” of all disclosures of personal health information.",
    ],
  },
  {
    title: "2. High standards for NFC-embedded medical ID products",
    type: "list",
    body: "All organisations offering NFC-embedded medical ID jewelry or devices should ensure these products can be appropriately understood and identified in the event of a medical emergency. Medical ID companies should have:",
    list: [
      "The high-quality NFC silicon wristband and metal pendant, featuring a hypoallergenic chain, are crafted with advanced technology for both durability and security. The state-of-the-art NFC technology ensures seamless, encrypted communication with compatible devices, providing instant access to critical medical information. Designed to withstand various conditions, these devices ensure that vital data remains legible and accessible, enhancing user safety in emergencies.",
      "Data entry standards that delineate the hierarchy or priority of medical information included and adhere to medical terminology standards.",
      "Medical terminology standards for data entry that require the use of terms and abbreviations accepted by the national standard setting organisation in your country.",
      "Medication standards covering use of generic and/or brand name medications.",
    ],
  },
  {
    title: "3. Safe and effective medical ID jewelry and products",
    type: "list",
    body: "All organisations offering jewelry or similar ID products (e.g., bracelets, dog tags, shoe tags, etc.) should have policies to ensure the safety and functionality of the product, including:",
    list: [
      "A commitment to follow national product safety guidelines such as those promulgated by the ACCC or similar standard setting bodies in other countries.",
      "A commitment to ensure ongoing product testing for lead or other toxic materials comply with all consumer protection requirements.",
      "A commitment to sell products that are safe for children and other vulnerable populations (e.g. does not pose a choking hazard for children).",
      "A commitment to sell or provide only medical ID product designs and emblems easily recognized by MyResQ and others in the medical field.",
      "A commitment to institute active, nationwide programs to inform MyResQ and other emergency medical personnel so that their products and services will be universally recognized by MyResQ in the field during an emergency.",
    ],
  },
  {
    title: "4. No-fail emergency response services",
    type: "list",
    body: "All organisations offering emergency response services that communicate with MyResQ or hospital emergency departments about your vital medical information, including emergency contacts and family notification services, should have policies and procedures designed to ensure:",
    list: [
      "The emergency services are available 24 hours a day, 7 days a week, and if live call center services are being provided, the emergency response service center is adequately staffed with trained operators. In addition, if an automated computerised response system is being provided, be sure to understand the nature and extent of the information about you that will be communicated and that there is sufficient capacity and functionality to handle multiple inquiries simultaneously from emergency responders.",
      "Personal health information will be provided only to authorised MyResQ and medical personnel at a time of need and that a process exists to identify/verify that the MyResQ or hospital accessing the emergency record is authorised.",
      "A record or an audit trail is maintained of when your information disclosed to MyResQ or other authorised persons; who has accessed what information, when and for what purpose.",
      "Health records provided are “date stamped” for last date of validation or review to enable emergency responders to assess the integrity and currency of the information.",
      "At least one channel, but preferably multiple channels, for communication with emergency responders is available (i.e., telephone, secure fax, and/or secure web access etc.).",
      "Consumers are informed prior to purchase whether emergency response services are rendered by a “live” call center or an automated electronic system.",
      "Family notification services (if offered) are performed on a timely basis with established protocols on the nature of information that is communicated and the method used to communicate that information to designated contacts.",
      "The availability of language translation services to address languages not common to country of origin.",
    ],
  },
  {
    title: "5. Better standards of business",
    type: "list",
    body: "In addition to the usual standard better business practices, all organisations should have policies and procedures designed to:",
    list: [
      "Ensure consumers, their families, and emergency callers are treated with dignity and respect.",
      "Provide emergency information to authorised emergency responders regardless of consumer’s payment status for the service at the time of an emergency.",
      "Address consumer complaints with a clearly established and articulated complaint process.",
      "Ensure consumer has the right to terminate services for any reason.",
    ],
  },
];

export default (props) => {
  return (
    <div className="container code-conduct">
      <div className="text-center mb-5">
        <p className="header-title text-primary mb-0">MedicAlert™</p>
        <p className="header-title mb-3">Code of Conduct</p>
        <p>Read about our organisation’s Code of Conduct</p>
      </div>
      <div className="bottom-body">
        <Accordion defaultActiveKey="0">
          {accordionList?.map((item, index) => {
            return (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body
                  className="text-secondary"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {item.body}
                  {item.type === "list" && (
                    <ul className="mt-4">
                      {item.list.map((x, ind) => {
                        return (
                          <li className="text-dark" key={ind}>
                            {x}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};
