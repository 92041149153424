import React from "react";

function BannerDots() {
  return (
    <div className="dots-container">
      {Array.from({ length: 4 }, (_, i) => (
        <div className={`dot ${i === 0 ? "active" : ""}`} key={i}></div>
      ))}
    </div>
  );
}

export default BannerDots;
