import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { ChevronDown } from "react-bootstrap-icons";

import resourcesBanner from "Assets/images/resources/resources-banner.png";
import resourcesBannerMobile from "Assets/images/resources/resources-banner-mobile.png";
import postageImg from "Assets/images/resources/postage-img.png";
import returnImg from "Assets/images/resources/return-img.jpg";
import postageIcon from "Assets/images/resources/postage-icon.svg";
import returnIcon from "Assets/images/resources/return-icon.svg";
import BottomContainer from "../../../components/common/SectionBottom/BottomContainer";
import BottomHeader from "../../../components/common/SectionBottom/BottomHeader";
import BottomBody from "../../../components/common/SectionBottom/BottomBody";
import BottomFooter from "../../../components/common/SectionBottom/BottomFooter";

import Banner from "../../../components/common/Banner";

import { useGlobalState } from "../../../context/GlobalStateProvider";

const postageReturnCard = [
  {
    icon: postageIcon,
    title: "Postage And Shipping",
    content:
      "Standard postage rate applied to all products. However, an express delivery is available for an additional cost.  If you would like an Express delivery, do reach out to our Customer Service if you require further details on Express deliveries.",
    toggled: true,
    index: 0,
  },
];

export default function PostageShipping(props) {
  return (
    <div className="health-pro-page">
      {/* Section Btm */}
      <BottomContainer>
        <BottomHeader>
          <div className="header-title">
            <div className="primary-color">Postage And Shipping</div>
          </div>
        </BottomHeader>
        <BottomBody>
          <div className="section-explanation">
            <div className="container">
              <div className="steps-desc-container resources-exp-container">
                {/* <div className="resource-box-img">
                  <img
                    src={postageImg}
                    alt="postage"
                    style={{ transform: "scale(1.05)" }}
                  />
                </div> */}
                {postageReturnCard.map((item) => (
                  <FeatureBox item={item} key={item.title} />
                ))}
                {/* <div className="resource-box-img">
                  <img src={returnImg} alt="return" />
                </div> */}
              </div>
            </div>
          </div>
        </BottomBody>
        <BottomFooter>
          <div className="title">
            <div className="title-head text-black">
              We're here, ready to help
            </div>
          </div>

          <Link className="primary-btn" to="/contact">
            Contact Us
          </Link>
        </BottomFooter>
      </BottomContainer>
    </div>
  );
}

function FeatureBox({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useGlobalState();

  useEffect(() => {
    if (isMobile) setIsOpen(item.index === 0 ? true : false);
    else setIsOpen(false);
  }, [isMobile]);

  const handleOpen = () => {
    if (!isMobile) return;
    setIsOpen((isOpen) => !isOpen);
  };

  const contentStyle = {
    maxHeight: isOpen ? "500px" : "7.2rem",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  };

  return (
    <div
      className={`feature-box resource-box ${
        item.index === 0 ? "left" : "right"
      } ${isOpen ? "open" : ""}`}
      style={isMobile ? contentStyle : {}}
    >
      <div className="m-dev">
        <div
          className={`feature-box-header flex al-i-c ${
            isOpen ? "ju-c-end" : "ju-c-sb"
          }`}
          onClick={handleOpen}
        >
          {!isOpen && (
            <div className="primary-color font-bold">{item.title}</div>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.599"
            height="9"
            viewBox="0 0 16.599 9"
          >
            <path
              id="Polygon_31"
              data-name="Polygon 31"
              d="M8.3,0l8.3,9H0Z"
              transform="translate(16.599 9) rotate(180)"
              fill="#061528"
            />
          </svg>
        </div>
      </div>
      <div className="feature-box-body">
        <div className="feature-img text-center">
          <img src={item.icon} alt={item.title} />
        </div>
        <div className="feature-desc">
          <div className="feature-desc-title primary-color font-bold mb-3">
            {item.title}
          </div>
          <div className="feature-desc-body secondary-color">
            {item.content}
          </div>
        </div>
      </div>
    </div>
  );
}
