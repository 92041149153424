import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import kwanImg from "../../../../assets/images/testimonials/kwanImg.png";
import anneImg from "../../../../assets/images/testimonials/anneImg.png";
import bennyImg from "../../../../assets/images/testimonials/bennyImg.png";

import { Fade, Slide } from "react-awesome-reveal";

const membersData = [
  {
    name: "Anne Marie Gomez",
    image: anneImg,
    testimonials:
      "Anne Marie Gomez, a member of MedicAlert since 2019, says that wearing the Medicalert ID helps to inform people about her current needs, and medical condition",
  },
  {
    name: "Kwan How Chen",
    image: kwanImg,
    testimonials:
      'Being our member since 2003, Mr Kwan How Chen tells us to "change without pondering" when it comes to changing the old bracelet for the new NFC bracelet and pendant',
  },
  {
    name: "Benny Teh",
    image: bennyImg,
    testimonials:
      "Benny Teh, a member since 2000, received a MedicAlert bracelet during a hospital visit when emergency personnel discovered his asthma and allergies. He emphasizes the lifesaving significance of wearing the bracelet, underscoring its critical role in emergencies where detecting his allergies could be a matter of life or death.",
  },
];

const members = membersData.map((el, i) => {
  if ((i + 1) % 2 === 0) return { ...el, reverseOrder: true };
  else return el;
});

export default function Testimonials(props) {
  return (
    <div className="testimonials-page">
      {/* Header */}
      <section className="top-title">
        <div className="primary-color">Member Testimonials</div>
      </section>

      {/* Board Boxes */}
      <section className="section-body">
        <div className="container">
          <div className="testimonal-row-wrapper">
            {members.map((member) => (
              <TestimonialRow member={member} key={member.name} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

function TestimonialRow({ member }) {
  return (
    <div className="testimonial-row">
      <Slide
        direction="up"
        className="member-img-container"
        style={member.reverseOrder ? { order: 2 } : {}}
        triggerOnce={true}
      >
        <div className="member-img">
          <img src={member.image} alt="member" />
        </div>
      </Slide>
      <div className="member-info">
        <div
          className="member-name"
          style={member.reverseOrder ? { textAlign: "right" } : {}}
        >
          {member.name}
        </div>
        <div className="member-story">
          <Fade delay={0} cascade damping={0.01} triggerOnce={true}>
            {member.testimonials}
          </Fade>
        </div>
      </div>
    </div>
  );
}
