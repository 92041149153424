import React, { useState } from "react";
import { Link } from "react-router-dom";

import topbarLogo from "Assets/images/medical-alert-logo.png";
import menuIcon from "Assets/images/menu-icon.svg";
import loginIcon from "Assets/images/user.svg";

const topbarHoverMenuItem = {
  Resources: [
    {
      name: "Collaboration Partners",
      link: "/collaboration-partners",
    },
    {
      name: "Code of Conduct",
      link: "/code-of-conduct",
    },
    {
      name: "FAQ",
      link: "/faq",
    },
    {
      name: "Training & Support",
      link: "/training-&-support",
    },
    {
      name: "Postage & Shipping",
      link: "/postage-&-shipping",
    },
  ],
  About: [
    {
      name: "Our Story",
      link: "/about",
    },
    {
      name: "Meet Our Board",
      link: "/meet-our-board",
    },
    {
      name: "Our Endorsers",
      link: "/endorsement",
    },
  ],
};

function Topbar({ logoutHandler, onOpenMobMenu, isLoggedIn }) {
  const isNew = localStorage.getItem("is_new_user") === "1";

  return (
    <nav className="topbar">
      <div className="topbar-items-wrapper">
        <div className="nav-left">
          <Link to="/home">
            <img
              className="topbar-logo"
              src={`${topbarLogo}`}
              alt="Medical Alert Logo"
            />
          </Link>
        </div>
        <div className="nav-right">
          {!isLoggedIn() ? (
            <>
              <div className="d-dev">
                <div className="links">
                  <Link to="/about"></Link>
                  <TopbarHoverMenu menuName="About" />
                  <Link to="/membership">Features and Benefits</Link>
                  <TopbarHoverMenu menuName="Resources" />
                  <Link to="/testimonials">Testimonials</Link>
                  <Link to="/donate">Donate</Link>
                  <Link to="/contact">Contact Us</Link>
                </div>
              </div>
              <div className="d-dev">
                <Link className="login-button" to="/login">
                  <img
                    width={20}
                    src={loginIcon}
                    style={{ marginRight: "5px" }}
                    alt="user icon"
                  />
                  Log In
                </Link>
              </div>
              <div className="m-dev menu-btn" onClick={onOpenMobMenu}>
                <img src={menuIcon} alt="menu" />
              </div>
            </>
          ) : (
            <>
              <div className="links d-dev">
                <Link to="/account">My Account</Link>
                <Link to="/payment">My Payment</Link>
                {!isNew &&
                <Link to="/profile">My Profile</Link>
                }
              </div>
              <div className="links d-dev">
                <Link className="login-button" to="/donate">
                  Donate
                </Link>
                <a
                  className="login-button d-dev"
                  onClick={() => logoutHandler()}
                >
                  Log Out
                </a>
              </div>
              <div className="m-dev menu-btn" onClick={onOpenMobMenu}>
                <img src={menuIcon} alt="menu" />
              </div>
            </>
          )}
          {/* <div className="login-button">Log In</div> */}
        </div>
      </div>
    </nav>
  );
}

function TopbarHoverMenu({ menuName }) {
  const [isHoverMenu, setIsHoverMenu] = useState(false);
  const handleOpenResourcesMenu = () => {
    setIsHoverMenu(true);
  };

  const handleCloseResourcesMenu = () => {
    setIsHoverMenu(false);
  };
  return (
    <div
      className="resources-links-buttons"
      onMouseOver={handleOpenResourcesMenu}
      onMouseLeave={handleCloseResourcesMenu}
    >
      <div className="resource-link-display">
        {menuName}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.599"
          height="9"
          viewBox="0 0 16.599 9"
        >
          <path
            id="Polygon_31"
            data-name="Polygon 31"
            d="M8.3,0l8.3,9H0Z"
            transform="translate(16.599 9) rotate(180)"
            fill="#061528"
          />
        </svg>
      </div>
      {isHoverMenu && (
        <div className="resources-links-container">
          <ul className="resources-link">
            <li className="resources-link-header">
              <div>{menuName}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.599"
                height="9"
                viewBox="0 0 16.599 9"
              >
                <path
                  id="Polygon_31"
                  data-name="Polygon 31"
                  d="M8.3,0l8.3,9H0Z"
                  transform="translate(16.599 9) rotate(180)"
                  fill="#061528"
                />
              </svg>
            </li>
            {topbarHoverMenuItem[menuName].map((x) => (
              <li key={x.name}>
                <Link to={x.link}>{x.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Topbar;
