import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { updateAllergies } from "../../../../../ducks/services";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";

// create allergy_users
export default (props) => {
  const { user, meta, isMobile } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();


  useEffect(() => {
    if (user) {
      user?.allergy_users?.forEach(d => {
        setValue(`${d.allergy_code}`, true);
        if(d.allergy_code === "OTHER") {
          setValue("desc_other", d.desc_other);
        }
      });
    }
  }, [user]);

  const onFinishDoctorDetails = async (val) => {
    props.setLoading(true);

    let newObj = Object.fromEntries(
      Object.entries(val).filter(([key, value]) => key !== "")
    );

    let temp = meta?.allergies?.map(x => (Object.keys(newObj).find(key => key === x.code) && newObj[x.code] === true) ? x.code : null);

    const payload = {
      allergies: JSON.stringify(temp.filter(x => x !== null)),
      desc_other: val?.desc_other
    };


    await updateAllergies(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success("Allergies Updated Successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <Form onSubmit={handleSubmit(onFinishDoctorDetails)}>
      <div className="next-of-kin">
        <div className="row mb-5">
          {
            meta?.allergies?.map((item, index) => {
              return (
                <div className="col-6 col-md-3 mb-3" key={index}>
                  <Form.Check
                    {...register(item.code)}
                    label={item.desc}
                    type={"checkbox"}
                    id={item.code}
                  />
                </div>
              )
            })
          }
          {
            watch("OTHER") === true &&
            <div className="col-12">
              <Form.Group controlId="formContact" className="mt-3">
                <Form.Label className="text-muted">Other:</Form.Label>
                <Form.Control
                  {...register("desc_other")}
                  size="lg"
                  type="text"
                />
              </Form.Group>
            </div>
          }
        </div>
        <div className="row justify-content-end">
          <div className={`col-${isMobile ? "12" : "6"} mb-3 align-self-center text-right`}>
            <Button
              size="lg"
              disabled={props.loading}
              className={`${isMobile ? "primary-btn text-white" : "secondary-btn"}`}
              type="submit"
              style={{
                background: `${isMobile ? "#ed174e" : "unset"}`,
                width: "100%",
                maxWidth: `${isMobile ? "100%" : "268px"}`,
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
};