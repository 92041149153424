import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as TabCards from './tabList';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');

  const tabs = [
    {
      key: "policy",
      title: "Privacy Policy",
      Comp: 'Policy',
    },
    {
      key: "tnc",
      title: "Terms And Condition",
      Comp: 'Tnc',
    },
  ];

  return (
    <div className='privacy'>
      <Tabs
        defaultActiveKey="policy"
        id="fill-tab-example"
        className="mb-5 eq-size"
      >
        {
          tabs.map((item, index) => {
            const Cardi = TabCards[item.Comp];
            return (
              <Tab eventKey={item.key} title={item.title} key={index}>
                <Cardi {...props} />
              </Tab>
            )
          })
        }
      </Tabs>
    </div>
  );
};
