import React, { useState } from "react";
import DonateBar from "./DonateBar";
import BannerInfo from "./BannerInfo";
import BannerDots from "./BannerDots";
import { useGlobalState } from "../../context/GlobalStateProvider";

function Banner({
  hasDonateBar = false,
  hasBannerInfo = false,
  hasBannerDots = false,
  webBanner,
}) {
  const { isMobile } = useGlobalState();

  return (
    <section className="section-banner">
      <div className="banner-container">
        <img className="banner-image" src={webBanner} alt="banner" />
        {/* Donate bar */}
        {hasDonateBar && <DonateBar />}

        {/* Banner Info */}
        {hasBannerInfo && <BannerInfo />}

        {/* Dots */}
        {hasBannerDots && <BannerDots />}
      </div>
    </section>
  );
}

export default Banner;
