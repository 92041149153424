import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { updateNextOfKin } from "../../../ducks/services";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";

export default (props) => {
  const { user, isMobile } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const formFields = [
    {
      name: "emergency_contact_name",
      label: "Full Name",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Full Name is Required",
      size: "lg",
    },
    {
      name: "emergency_contact_relationship",
      label: "Relationship",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Relationship is Required",
      size: "lg",
    },
    {
      name: "emergency_contact_phone",
      label: "Phone (Mobile)",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Phone (Mobile) is Required",
      size: "lg",
    },
    {
      name: "emergency_address",
      label: "Address",
      type: "input",
      req: false,
      class: "col-md-6 col-12",
      reqmsg: "Address is Required",
      size: "lg",
    },
    {
      name: "second_emergency_contact_name",
      label: "Second Emergency Contact's Full Name",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Second Emergency Contact's Full Name is Required",
      size: "lg",
    },
    {
      name: "second_emergency_contact_relationship",
      label: "Second Emergency Contact's Relationship",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Second Emergency Contact's Relationship is Required",
      size: "lg",
    },
    {
      name: "second_emergency_contact_phone",
      label: "Second Emergency Contact's Phone (Mobile)",
      type: "input",
      req: true,
      class: "col-md-6 col-12",
      reqmsg: "Second Emergency Contact's Phone (Mobile) is Required",
      size: "lg",
    },
    {
      name: "second_emergency_address",
      label: "Second Emergency Contact's Address",
      type: "input",
      req: false,
      class: "col-md-6 col-12",
      reqmsg: "Address is Required",
      size: "lg",
    },
  ];

  useEffect(() => {
    if (user) {
      setValue("emergency_contact_name", user.emergency_contact_name);
      setValue(
        "emergency_contact_relationship",
        user.emergency_contact_relationship
      );
      setValue("emergency_contact_phone", user.emergency_contact_phone);
      setValue("emergency_address", user.emergency_address);

      setValue(
        "second_emergency_contact_name",
        user.second_emergency_contact_name
      );
      setValue(
        "second_emergency_contact_relationship",
        user.second_emergency_contact_relationship
      );
      setValue(
        "second_emergency_contact_phone",
        user.second_emergency_contact_phone
      );
      setValue("second_emergency_address", user.second_emergency_address);
    }
  }, [user]);

  const onFinishNextOfKin = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
    };

    await updateNextOfKin(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          toast.success("Emergency Contact Name Details Updated Successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  return (
    <Form onSubmit={handleSubmit(onFinishNextOfKin)}>
      <div className="next-of-kin">
        <div className="row">
          {formFields?.map((item, index) => {
            return (
              <Fragment key={index}>
                <FormGroup item={item} errors={errors} register={register} />
              </Fragment>
            );
          })}
        </div>
        <div className="row justify-content-end mt-5">
          <div
            className={`col-${
              isMobile ? "12" : "6"
            } mb-3 align-self-center text-right`}
          >
            <Button
              size="lg"
              disabled={props.loading}
              className={`${
                isMobile ? "primary-btn text-white" : "secondary-btn"
              }`}
              type="submit"
              style={{
                background: `${isMobile ? "#ed174e" : "unset"}`,
                width: "100%",
                maxWidth: `${isMobile ? "100%" : "268px"}`,
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
