import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

function PricingPackage({
  handleSelectPackage,
  selectedPackage,
  pricingPackageList,
  pricingPackageRef,
}) {
  return (
    <div className="pricing-package-container m-t-2" ref={pricingPackageRef}>
      <div className="pricing-boxes">
        {pricingPackageList.map((item) => (
          <div
            className={`pricing-box`}
            onClick={() => handleSelectPackage(item.package)}
            key={item.title}
          >
            <div>
              <div className="pricing-box-title">{item.title}</div>
              {item.tips && (
                <div className="pricing-box-tips fs-14 font-italic">
                  {item.tips}
                </div>
              )}
            </div>

            <div className="pricing-box-amt">{item.price}</div>
          </div>
        ))}
      </div>

      <div className="pricing-foreigner">
        {
          "(For non-Malaysians, all the above membership fees and medical ID device costs are doubled)"
        }
      </div>
    </div>
  );
}

export default PricingPackage;
