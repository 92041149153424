import React, { useEffect, useState } from "react";
import stepImg from "Assets/images/step-img.svg";
import validateRecordImg from "Assets/images/home-btm-img/validate-record.svg";
import marketLeadingServiceImg from "Assets/images/home-btm-img/market-leading-service.jpg";
import qualityIdImg from "Assets/images/home-btm-img/quality-id.svg";
import homeBanner from "Assets/images/home-banner.jpg";
import homeBannerMobile from "Assets/images/home-banner.jpg";
import { Link } from "react-router-dom";

// components
import TopTitle from "../../components/common/TopTitle";
import Banner from "../../components/common/Banner";
import TextLeftImageRight from "../../components/common/TextLeftImageRight";
import BigTitle from "../../components/common/BigTitle";
import BottomContainer from "../../components/common/SectionBottom/BottomContainer";
import BottomHeader from "../../components/common/SectionBottom/BottomHeader";
import BottomBody from "../../components/common/SectionBottom/BottomBody";
import BottomFooter from "../../components/common/SectionBottom/BottomFooter";
import BottomBox from "../../components/common/SectionBottom/BottomBox";

const stepBoxes = [
  {
    title: "1. Choose a custom MedicAlert ID",
    content:
      "Consider Your Preferences: Determine whether you prefer wearing a bracelet or a pendant. Think about your daily activities, comfort, and style preferences.",
    hoverText: "Shop Now",
    icon: "watch",
  },
  {
    title: "2. Create your MedicAlert Record",
    content:
      "Build your MedicAlert Record to speak for you in times you can’t. Consolidate health information, documents, and plans into a secure online record, easily accessible for updates.",
    hoverText: "Learn More",
    icon: "record",
  },
  {
    title: "3. Enjoy 24/7 peace of mind",
    content:
      "Support MedicAlert for RM 250/year, aiding over 100,000 Malaysians. Ensure protection during your most vulnerable moments.",
    hoverText: "Learn More",
    icon: "24x7",
  },
];

const bottomBoxes = [
  {
    title: "Market Leading Service",
    body: "Our wristband and pendant, which are NFC chip-based, allow you to instantly and securely share your important health information with medical professionals. This means that in an emergency, they can quickly access the details they need to provide you with immediate and accurate treatment, which is crucial when time is of the essence.",
    image: marketLeadingServiceImg,
  },
  {
    title: "Quality MedicAlert IDs",
    body: "Durable and stylish, our high-quality IDs are built to last, ensuring you’re always prepared to make the most out of life when it matters.",
    image: qualityIdImg,
  },
  {
    title: "Twice Validated Records",
    body: "Every record undergoes thorough checks against global standards and by our team ensuring accurate information for MyResQ and healthcare professionals in critical decisions.",
    image: validateRecordImg,
  },
];

export default function Home(props) {
  return (
    <div className="home-page">
      {/* Header */}
      <TopTitle>
        <div className="primary-color">24/7 Protection,</div>
        <div className="black-text">Wherever & Whatever You Do</div>
      </TopTitle>

      {/* Banner */}
      <Banner
        hasDonateBar={true}
        hasBannerInfo={true}
        webBanner={homeBanner}
        mobileBanner={homeBannerMobile}
      />

      {/* Steps */}
      <section className="section-steps">
        <TextLeftImageRight>
          <div className="tl-content">
            <div className="tl-content-title ">
              <div className="primary-color">Set Up Your</div>
              <div>24/7 Confidence In 3 Steps</div>
            </div>
            <div className="tl-content-body secondary-color">
              These devices are incredibly simple to set up, and they offer
              invaluable benefits. With them, you can easily scan them with your
              smartphone to access all your medical details in case of an
              emergency, providing peace of mind for you and your loved ones.
            </div>
          </div>
          <div className="ir-content">
            <img src={stepImg} alt="MedicAlert" />
          </div>
        </TextLeftImageRight>

        <div className="steps-boxes">
          {stepBoxes.map((item) => {
            return <StepBox item={item} key={item.title} />;
          })}
        </div>
      </section>

      {/* Middle Title */}
      <BigTitle>
        <h1 className="text-capitalize">
          “You’re always in the{" "}
          <span className="primary-color">safest possible hands”</span>
        </h1>
        <p className="secondary-color">
          MedicAlert Foundation assists Malaysians with hidden health conditions
          to live with confidence.
        </p>
      </BigTitle>

      {/* Bottom Box */}
      <BottomContainer>
        <BottomHeader>
          <div className="header-title">
            <div className="primary-color">MedicAlert</div>
            <div className="black-text">is more than an ID</div>
          </div>
        </BottomHeader>
        <BottomBody>
          {bottomBoxes?.length && (
            <div className="bottom-boxes horizontal">
              {bottomBoxes.map((item) => {
                return (
                  <BottomBox
                    bottomBoxesItem={item}
                    isWhiteBg={true}
                    isImgScale={true}
                    key={item.title}
                  />
                );
              })}
            </div>
          )}
        </BottomBody>
        <BottomFooter>
          <Link className="primary-btn d-dev" to="/membership">
            Donate Now
          </Link>
          <Link className="primary-btn m-dev" to="/sign-up">
            Donate Now
          </Link>
        </BottomFooter>
      </BottomContainer>
    </div>
  );
}

function StepBox({ item }) {
  // const [hoverStepBox, setHoverStepBox] = useState(false);
  // const handleStepBoxMouseEnter = () => {
  //   setHoverStepBox(true);
  // };

  // const handleStepBoxMouseLeave = () => {
  //   setHoverStepBox(false);
  // };

  return (
    <div
      className="step-box"
      // onMouseEnter={handleStepBoxMouseEnter}
      // onMouseLeave={handleStepBoxMouseLeave}
    >
      <div className="step-box-header">
        {item.icon === "watch" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31.93"
            height="51.084"
            viewBox="0 0 31.93 51.084"
          >
            <g id="a-bianzu40" transform="translate(-180.706)">
              <path
                id="Path_84"
                data-name="Path 84"
                d="M203.058,0a3.2,3.2,0,0,1,3.191,3.194v5.33a9.58,9.58,0,0,1,6.386,9.033V33.524a9.58,9.58,0,0,1-6.386,9.033V47.89a3.2,3.2,0,0,1-3.194,3.194H190.283a3.194,3.194,0,0,1-3.191-3.194V42.563a9.584,9.584,0,0,1-6.386-9.036V17.561a9.588,9.588,0,0,1,6.386-9.036V3.194A3.2,3.2,0,0,1,190.283,0h12.772Zm0,43.1H190.286v4.789h12.772Zm0-31.93H190.286a6.386,6.386,0,0,0-6.371,5.909l-.018.477V33.527a6.389,6.389,0,0,0,5.909,6.371l.477.015h12.772A6.386,6.386,0,0,0,209.426,34l.018-.477V17.561a6.386,6.386,0,0,0-5.909-6.371l-.477-.015Zm-6.386,19.958a2.394,2.394,0,1,1-2.394,2.394A2.394,2.394,0,0,1,196.672,31.133Zm0-7.983a2.394,2.394,0,1,1-2.394,2.394A2.394,2.394,0,0,1,196.672,23.149Zm0-7.983a2.394,2.394,0,1,1-2.394,2.394A2.394,2.394,0,0,1,196.672,15.166Zm6.386-11.972H190.286V7.983h12.772V3.194Z"
                fill="#ed174e"
              />
            </g>
          </svg>
        )}

        {item.icon === "record" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38.63"
            height="51.084"
            viewBox="0 0 38.63 51.084"
          >
            <g id="line-report" transform="translate(-128 -64)">
              <path
                id="Path_85"
                data-name="Path 85"
                d="M161.8,64H132.829C130.166,64,128,66.455,128,69.473v40.138c0,3.018,2.166,5.473,4.829,5.473H161.8c2.663,0,4.829-2.455,4.829-5.473V69.473C166.63,66.455,164.464,64,161.8,64Zm1.61,45.611a1.729,1.729,0,0,1-1.61,1.824H132.829a1.729,1.729,0,0,1-1.61-1.824V69.473a1.729,1.729,0,0,1,1.61-1.824H161.8a1.729,1.729,0,0,1,1.61,1.824Z"
                fill="#ed174e"
              />
              <path
                id="Path_86"
                data-name="Path 86"
                d="M342.067,256H322.006a1.833,1.833,0,1,0,0,3.649h20.061a1.833,1.833,0,1,0,0-3.649Zm-8.024,12.771H322.006a1.833,1.833,0,1,0,0,3.649h12.037a1.833,1.833,0,1,0,0-3.649Z"
                transform="translate(-184.721 -181.053)"
                fill="#ed174e"
              />
            </g>
          </svg>
        )}

        {item.icon === "24x7" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46.408"
            height="51.084"
            viewBox="0 0 46.408 51.084"
          >
            <g
              id="_--hours-"
              data-name="--hours-"
              transform="translate(-46.869 0.002)"
            >
              <path
                id="Path_87"
                data-name="Path 87"
                d="M86.48,11.482A1.751,1.751,0,1,0,84,13.959,19.7,19.7,0,1,1,66.561,8.5l-2.662,3.9a1.751,1.751,0,1,0,2.894,1.974l4.744-6.955a1.751,1.751,0,0,0-.459-2.435L64.205.3A1.751,1.751,0,0,0,62.232,3.2L65.173,5.2A23.212,23.212,0,1,0,86.48,11.482Z"
                transform="translate(0 0)"
                fill="#ed174e"
              />
              <path
                id="Path_88"
                data-name="Path 88"
                d="M257.875,385.8a5.745,5.745,0,1,0-11.49,0,1.751,1.751,0,1,0,3.5,0,2.243,2.243,0,1,1,3.828,1.585q-.031.031-.061.064L246.836,395a1.751,1.751,0,0,0,1.3,2.926h7.988a1.751,1.751,0,0,0,0-3.5H252.08l4.146-4.586A5.7,5.7,0,0,0,257.875,385.8Zm1.812,7.477a1.752,1.752,0,0,0,1.564.963h6.155v1.926a1.751,1.751,0,0,0,3.5,0v-1.926h.225a1.751,1.751,0,0,0,0-3.5h-.225v-8.931a1.751,1.751,0,0,0-3.159-1.042l-7.906,10.682A1.751,1.751,0,0,0,259.687,393.281Zm7.719-6.161v3.621h-2.68Z"
                transform="translate(-189.562 -361.101)"
                fill="#ed174e"
              />
            </g>
          </svg>
        )}

        <div className="step-box-arrow">
          {/* {hoverStepBox && <span>{item.hoverText}</span>} */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30.359"
            height="22"
            viewBox="0 0 30.359 22"
          >
            <path
              id="cc-arrow-right"
              d="M93.245,201.026l-9.5-9.693a.78.78,0,0,0-1.119,0,.823.823,0,0,0,0,1.142l8.146,8.313H64.987a.808.808,0,0,0,0,1.616H90.775l-8.146,8.314a.823.823,0,0,0,0,1.142.785.785,0,0,0,1.122,0l9.5-9.694a.822.822,0,0,0,0-1.142Z"
              transform="translate(-63.696 -190.597)"
              fill="#ed174e"
              stroke="#ed174e"
              strokeWidth="1"
            />
          </svg>
        </div>
      </div>
      <div className="step-box-body">
        <div className="step-box-body-title">{item.title}</div>
        <div className="step-box-body-content">{item.content}</div>
      </div>
    </div>
  );
}
