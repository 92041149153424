import React, { useEffect, useState } from "react";
import LoginLayout from "../../templates/LoginLayout";
import Login from "Modules/Login";
import ForgotPassword from "Modules/ForgotPassword";
import ResetPassword from "Modules/ResetPassword";
import { isLoggedIn } from "../../../routing/config/utils";
import { Link, useNavigate } from "react-router-dom";

export default (props) => {
  const [loading, setLoading] = useState(false);
  const auth = isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      navigate("/account");
    }
  }, []);

  return (
    <LoginLayout loading={loading}>
      {/* { loading && <Spinner animation="border" /> } */}
      {props.Comp == "Login" && (
        <Login loading={loading} setLoading={setLoading} />
      )}
      {props.Comp == "ForgotPassword" && <ForgotPassword loading={loading} setLoading={setLoading} />}
      {props.Comp == "ResetPassword" && <ResetPassword loading={loading} setLoading={setLoading} />}
      {/* {props.Comp == 'OPTPCode' && <OptpCode load={setLoading} />}
      {props.Comp == 'Country' && <Country load={setLoading} setFlexcol={setFlexcol} />} */}
    </LoginLayout>
  );
};
