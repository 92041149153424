import React from "react";
import { Link, useNavigate } from "react-router-dom";

import footerLogo from "Assets/images/footer-logo.png";
import footerLogoMob from "Assets/images/mob-footer-logo.png";
import footerCharityLogo from "Assets/images/footer-charity.svg";

function Footer() {
  return (
    <footer className="section-footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-logo">
            <img
              className="max-w-150"
              src={footerLogo}
              alt="Medical Alert Logo"
            />
            {/* <img
              className="m-dev mob-logo"
              src={footerLogoMob}
              alt="Medical Alert Logo"
            /> */}
          </div>
          <div className="footer-left-link">
            <Link to="/privacy">Privacy Policy</Link>
            <div>|</div>
            <Link to="/privacy">Terms & Conditions</Link>
          </div>
        </div>
        <div className="footer-middle">
          <div className="footer-middle-container">
            <div className="footer-middle-item">
              <Link to="/contact">
                <div className="middle-item-title">Contact Us</div>
              </Link>
              <div className="middle-item-content">
                <a href="tel:+60184710020">+60184710020</a>
                <a href="mailto:support@medicalert.org.my">
                  support@medicalert.org.my
                </a>
              </div>
            </div>
            <div className="footer-middle-item">
              <div className="middle-item-title">
                Acknowledgement of Country
              </div>
              <div className="btm-item-content">
                The MedicAlert Foundation acknowledges the traditional owners of
                the lands on which our services operate throughout Malaysia. We
                pay our respects to their Elders past, present and emerging.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footer-right img-container">
          <img
            className="registered-charity-img"
            src={footerCharityLogo}
            alt="Registered Charity"
          />
        </div> */}
      </div>
      <div className="section-copyright">
        <div className="copyright-container">
          <div className="copyright-title">
            © Copyright Malaysia MedicAlert Foundation 2024
          </div>
          <div className="copyright-content">
            The Malaysia MedicAlert Foundation is an independent charitable
            organisation organised under the laws of Malaysia. MedicAlert® is a
            registered trademark licensed by MedicAlert Foundation
            International.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
