import React from "react";

function BottomBox({ bottomBoxesItem, isWhiteBg, isImgScale }) {
  return (
    <div className={`bottom-box ${isWhiteBg ? "whiteBg" : "transparent"}`}>
      <div className="bottom-box-header">
        <img
          className={isImgScale ? "scale" : ""}
          src={bottomBoxesItem.image}
          alt={bottomBoxesItem.title}
        />
      </div>
      <div className="bottom-box-content">
        <div className="bottom-box-content-title">{bottomBoxesItem.title}</div>
        <div className="bottom-box-content-body">{bottomBoxesItem.body}</div>
      </div>
    </div>
  );
}

export default BottomBox;
