import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import accPageLogo from "Assets/images/acc-page-logo.svg";
import behImg from "../../../../assets/images/board/beh.png";
import afiqImg from "../../../../assets/images/board/dr-afiq.png";
import sooImg from "../../../../assets/images/board/dr-soo.png";
import aizuImg from "../../../../assets/images/board/dr-aizuddin.png";

const boardPerson = [
  {
    title: "President",
    name: "Beh Yee Chun",
    image: behImg,
  },
  {
    title: "Vice-President",
    name: "Dr. Mohd Afiq B Mohd Nor",
    image: afiqImg,
  },
  {
    title: "Hon.Secretary",
    name: "Dr. Soo Mei Yoke",
    academicLevel: "(Ph.D)",
    image: sooImg,
  },
  {
    title: "Hon.Treasurer",
    name: "Dr. Mohd Aizuddin",
    image: aizuImg,
  },
];

export default function MeetOurBoard(props) {
  return (
    <div className="meet-our-board-page">
      {/* Header */}
      <section className="top-title">
        <div className="primary-color">Meet our Board Of Governance</div>
      </section>

      {/* Header */}
      <section className="section-header">
        <div className="container text-center">
          The MedicAlert organization operates under a volunteer Board of
          Directors, overseeing governance, public accountability, and strategic
          priority setting.
        </div>
      </section>

      {/* Board Boxes */}
      <section className="section-board">
        <div className="container">
          <div className="board-boxes">
            {boardPerson.map((person) => (
              <div className="board-box" key={person.name}>
                <div
                  className="board-img"
                  style={{ backgroundImage: `url('${person.image}')` }}
                ></div>
                {/* <img src={person.image} alt={person.name} /> */}
                <div className="board-desc">
                  <div className="person-ttile">
                    {person.title.toUpperCase()}
                  </div>
                  <div className="person-info">
                    <div>{person.name}</div>
                    {person.academicLevel && <div>{person.academicLevel}</div>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
