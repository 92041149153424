import React from "react";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";

export default (props) => {
  const { item, register, errors, watch } = props;

  return (
    <div className={`${item.class}`}>
      {item.topLabel && (
        <div className={item?.topLabelBold ? "font-bold" : ""}>
          {item.topLabel}{" "}
        </div>
      )}
      <Form.Group
        controlId={item.name}
        className={`mb-3 ${item.hidden ? "d-none" : ""}`}
      >
        <Form.Label>
          {item.label} {item.req && <span className="text-danger">*</span>}
        </Form.Label>
        {item.type === "select" ? (
          <Form.Select
            size={item.size}
            placeholder={item.placeholder}
            isInvalid={Boolean(errors[item.name])}
            {...register(item.name, {
              required: item.req,
              ...item.registerProps,
            })}
            // onChange={item.onChange && item.onChange}
          >
            <option value="">{item.placeholder}</option>
            {item.options?.map((x, i) => {
              return (
                <option key={i} value={item.keypair ? x.value : x}>
                  {item.keypair ? x.label : x}
                </option>
              );
            })}
          </Form.Select>
        ) : (
          <Form.Control
            size={item.size}
            type={item.type}
            // placeholder={item.label}
            rows={item.rows}
            as={item.as}
            isInvalid={Boolean(errors[item.name])}
            {...register(item.name, {
              required: item.req,
              ...item.registerProps,
            })}
            autoComplete="off"
          />
        )}
        <Form.Control.Feedback type="invalid">
          {errors?.[item.name]?.type === "required"
            ? item.reqmsg
            : errors?.[item.name]?.message}
        </Form.Control.Feedback>
        {item.children &&
          item.children?.trigger &&
          item.children?.trigger === watch(item.name) && (
            <>
              <Form.Control
                className="mt-3"
                size={item.size}
                type={item.children?.type}
                placeholder={item.children.placeholder}
                isInvalid={Boolean(errors[item.children.name])}
                {...register(item.children.name, {
                  required: item.children.req,
                })}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.[item.children.name]?.type === "required"
                  ? item.children.reqmsg
                  : errors?.[item.children.name]?.message}
              </Form.Control.Feedback>
            </>
          )}
      </Form.Group>
    </div>
  );
};
